import React from 'react'
import { ErrorStyles } from './Error.css'
import { ErrorPropTypes } from './ErrorPropTypes'

import { useLocation } from "react-router-dom";


const Error = ({ errorMessage }: ErrorPropTypes) => {

  const location = useLocation();
  const { state } = location;

  // if (!errorMessage) errorMessage = state.errorMessage;

  return (
    <div className={ErrorStyles}>

      <h1>404</h1>
      Tobias
      {false && errorMessage && (
        <h5 data-testid="test-ErrorMessageHolder">{errorMessage}</h5>
      )}
    </div>
  )
}

export { Error }
