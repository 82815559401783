
import React, { createContext, useContext, useState } from 'react';



type TitleInfoContext = {
  title: string | null;
  hasBackButton: boolean,
  backLink: string | null,
};

type TitleInfoContextValue = {
  title: TitleInfoContext | null,
  setTitle: React.Dispatch<React.SetStateAction<TitleInfoContext | null>>,
}


// Create the context with an initial value (you can customize the initial state as needed)
const TitleInfoContextObject = createContext<TitleInfoContextValue | undefined>(undefined);

// Create a custom hook to access the AuthContext
export const useTitleInfo = (): TitleInfoContextValue => {
  const context = useContext(TitleInfoContextObject);
  if (!context) {
    throw new Error('useTitleInfo must be used within an MachineDataProvider');
  }
  return context;
};

interface TitleInfoProviderProps {
  children: React.ReactNode
}

// AuthProvider component that wraps your app and provides the context value
const TitleInfoProvider: React.FC<TitleInfoProviderProps> = ({ children }) => {

  const [title, setTitle] = useState<TitleInfoContext | null>(null);

  const value: TitleInfoContextValue = { title, setTitle };


  // Return the context provider with the children as its content
  return <TitleInfoContextObject.Provider value={value}>{children}</TitleInfoContextObject.Provider>;
};

export default TitleInfoContextObject;
export { TitleInfoProvider };
