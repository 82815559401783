import React from 'react'
import AppRoutes from './components/routes/AppRoutes'

export declare type LogoutFunction = () => void
export interface IAppProps {
  logout: LogoutFunction
}

function App({ logout }: IAppProps) {
  return (
    <div data-testid="test-appContainer">
      <AppRoutes logout={logout} />
    </div>
  )
}

export default App
