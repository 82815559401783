//@ts-nocheck
import React from 'react'
/*
import AzureAD, {
  AuthenticationState,
  IAzureADFunctionProps,
} from 'react-aad-msal'
*/
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {
  BrowserRouter, Route, Routes,/*, Navigate*/
  useNavigate
} from 'react-router-dom'
import LoginPage from './components/pages/loginPage/LoginPage'
//import { authProvider } from './authConfig'
import { AuthProvider, useAuth } from "./components/hooks/useAuth"
import { MachineDataProvider, useMachineData } from './components/hooks/useMachineData'

import { EvalAuth } from './components/pages/loginPage/EvalAuth'
import { TitleInfoProvider } from './components/hooks/useTitleInfo'
// Declare the alert function since it is not natively available in TypeScript
//declare function alert(message: string): void;



function login(): void {


  let url = ""
  if (process.env.REACT_APP_STAGE === "rel") {
    url =
      "https://siemens-qa-00432.eu.auth0.com/authorize?response_type=code&client_id=Q9HbFuJUowH2EQ2n5aeBS7lsa8XMy8iH&redirect_uri=https://rm.prodhealth.az.tpoc.datadrivenx.com/evalauth&scope=openid&audience=https://cedirectportddxcoreapi.azure-api.net/core/";
  }

  if (process.env.REACT_APP_STAGE === "debug") {
    url =
      "https://siemens-qa-00432.eu.auth0.com/authorize?response_type=code&client_id=Q9HbFuJUowH2EQ2n5aeBS7lsa8XMy8iH&redirect_uri=http://localhost:3000/evalauth&scope=openid&audience=https://cedirectportddxcoreapi.azure-api.net/core/";
  }




  //url = "http://localhost:3000/evalauth?code=debuggingforfelix";
  window.location.href = url;

}




function CheckAuthentication(): React.ReactElement {
  const { getAuth, deleteAuth } = useAuth();
  const navigate = useNavigate();
  const logout = (): void => {
    deleteAuth();
    navigate("/");
  }

  if (getAuth() !== "") {
    return <App logout={logout} />;
  } else {
    return <LoginPage login={login} />;
  }
}


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>

    <AuthProvider>
      <MachineDataProvider>
        <TitleInfoProvider>
          <Routes>
            <Route path="*" element={<CheckAuthentication />} />
            <Route path="evalauth" element={<EvalAuth />} />

          </Routes>
        </TitleInfoProvider>
      </MachineDataProvider>
    </AuthProvider>


  </BrowserRouter>
)

reportWebVitals()

