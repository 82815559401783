import { style } from '@vanilla-extract/css'

export const HomeRNVContainerStyle = style({
  position: 'relative',
  top: '45px',
  left: '45px',
  width: '95%',
})

/*
export const DefaultHomeContainerStyle = style({
  position: 'relative',
  width: '94vw',
  height: '90vh',
  top: '45px',
  left: '45px',
  paddingLeft: '2%',
  zIndex: 0,
})
*/

export const DefaultHomeContainerStyle = style({


  //height: '100%',

  //padding: '1rem',
  //  zIndex: 0,
})




export const breadCrumbContainerStyle = style({
  width: '100%',
  boxShadow: 'inset 0 -2px 0 0 #F3F3F0',
})

export const HomeLoaderStyle = style({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
