import React, { useEffect, useRef, useState } from 'react';
import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';

import Loki from 'lokijs';
import { IxCard, IxTile, IxTypography } from '@siemens/ix-react';
import { marginBottom } from '../../atoms/donut/constants';
import { emitWarning } from 'process';




import * as echarts from 'echarts';

interface MachineTypeSummaryProps {
  items?: DisplayMachinesMap | null | undefined;
  onClick?: (severity: null | number) => void;
  title?: string,
  layer?: string[],
  summary: { good: number, optimal: number, payattention: number, warning: number }
}




const DonutColors: Map<number, string> = new Map();
DonutColors.set(0, "#01893A"); //optimal
DonutColors.set(1, "#909700"); //normal
DonutColors.set(2, "#E96401"); //payattention
DonutColors.set(3, "#ED2005"); //warning




const DonutWidth: string = "170px";
const ColorMarkWidth: string = "12px"




type EChartsOption = echarts.EChartsCoreOption;

const option: EChartsOption = {
  series: [
    {
      hoverLayerThreshold: -1,
      hoverAnimation: false, // deactivate hover zooming
      selectedOffset: 0, // deactivate click highlighting  
      animation: false, // deactiviere Startanimation  
      type: 'pie',
      radius: ['70%', '90%'],
      startAngle: 225,
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 1,
        borderColor: '#fff',
        borderWidth: 1
      },
      label: {
        show: true,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: true
      },
      data: [
        { value: 6, itemStyle: { color: DonutColors.get(0), pointerEvents: 'none' } },
        { value: 3, itemStyle: { color: DonutColors.get(1), pointerEvents: 'none' } },
        { value: 1.5, itemStyle: { color: DonutColors.get(2), pointerEvents: 'none' } },
        { value: 1.5, itemStyle: { color: DonutColors.get(3), pointerEvents: 'none' } }

      ]
    }
  ]
};



const MachineTypeSummary: React.FC<MachineTypeSummaryProps> = ({ items, onClick, title, layer, summary }) => {

  const ChartRef = useRef<HTMLDivElement>(null);




  useEffect(() => {
    //var chartDom = document.getElementById('donut')!;
    var chartDom = ChartRef.current;

    chartDom?.addEventListener('click', (e) => {
      e.stopPropagation();
    });


    var myChart = echarts.init(chartDom);

    const option: EChartsOption = {
      series: [
        {
          hoverLayerThreshold: -1,
          hoverAnimation: false, // deactivate hover zooming
          selectedOffset: 0, // deactivate click highlighting  
          animation: false, // deactiviere Startanimation  
          type: 'pie',
          radius: ['70%', '90%'],
          startAngle: 225,
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 1,
            borderColor: '#fff',
            borderWidth: 1
          },
          label: {
            show: true,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          data: [
            { severity: 0, value: summary.optimal, itemStyle: { color: DonutColors.get(0), pointerEvents: 'none' } },
            { severity: 1, value: summary.good, itemStyle: { color: DonutColors.get(1), pointerEvents: 'none' } },
            { severity: 2, value: summary.payattention, itemStyle: { color: DonutColors.get(2), pointerEvents: 'none' } },
            { severity: 3, value: summary.warning, itemStyle: { color: DonutColors.get(3), pointerEvents: 'none' } }

          ]
        }
      ]
    };



    myChart.on('click', (params) => {
      if (params && params.event) {

        handleChartClick(params);
      }
      // Call your custom handler here

    });

    option && myChart.setOption(option);
    return () => {
      myChart.dispose();

      chartDom?.removeEventListener('click', (e) => {
        e.stopPropagation();
      });

    };
  }, [summary])

  const handleChartClick = (params: any) => {
    // Implement your logic based on the clicked segment
    console.log(`Clicked on ${params.data.severity}`);
    onClick && (typeof (params.data.severity) == 'number') && onClick(params.data.severity);
  };

  const OnHandleClick = () => {

    onClick && onClick(null);
  }



  return (
    <div onClick={OnHandleClick}>
      <div style={{ display: "block", backgroundColor: 'rgba(243, 243, 240)', padding: "1rem" }}>
        <IxTypography format="display-sm" style={{ marginBottom: "1rem" }}>Machine Type: {title}</IxTypography>
        <IxTypography format="label" style={{ marginBottom: "1rem" }}>Devices found: {summary.good + summary.optimal + summary.payattention + summary.warning}</IxTypography>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginBottom: "1rem" }}>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ backgroundColor: DonutColors.get(0), width: ColorMarkWidth, height: ColorMarkWidth, marginRight: "0.3rem" }}></div>
            <IxTypography format="label">Optimal</IxTypography>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ backgroundColor: DonutColors.get(1), width: ColorMarkWidth, height: ColorMarkWidth, marginRight: ColorMarkWidth }}></div>
            <IxTypography format="label">Normal</IxTypography>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ backgroundColor: DonutColors.get(2), width: ColorMarkWidth, height: ColorMarkWidth, marginRight: "0.3rem" }}></div>
            <IxTypography format="label">Pay Attention</IxTypography>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ backgroundColor: DonutColors.get(3), width: ColorMarkWidth, height: ColorMarkWidth, marginRight: "0.3rem" }}></div>
            <IxTypography format="label">Warning</IxTypography>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}> {/* Flex-Container zur horizontalen Zentrierung */}
          <div style={{ position: 'relative', width: DonutWidth, height: DonutWidth }}> {/* Container für Donut und Icon */}

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>

              <svg xmlns="http://www.w3.org/2000/svg" width="56" height="50" viewBox="0 0 56 50" fill="none">
                <path d="M28 0V50H0V0H28ZM22.4 5.55556H5.6V44.4444H22.4V5.55556ZM8.4 8.33333H19.6V22.2222H8.4V8.33333ZM12.6 27.7778H15.4V30.5556H12.6V27.7778ZM12.6 36.1111H15.4V38.8889H12.6V36.1111ZM19.6 31.9444V34.7222H16.8V31.9444H19.6ZM11.2 31.9444V34.7222H8.4V31.9444H11.2ZM36.4 0H42V50H36.4V0ZM22.4 0H56V5.55556H22.4V0ZM22.4 44.4444H56V50H22.4V44.4444ZM50.4 0H56V50H50.4V0Z" fill="#C8C8C2" />
              </svg>

            </div>
            {/* Hier ist dein Donut */}
            <div ref={ChartRef} id="donut" style={{ position: 'relative', width: DonutWidth, height: DonutWidth }}></div>
          </div>
        </div>


      </div>

    </div>
  );


}

export { MachineTypeSummary };

/*

*/