import React, { useEffect } from 'react';

import { IxTypography } from '@siemens/ix-react';

import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';

import { useState } from 'react';

import { useParams } from 'react-router-dom';


import { SearchInput } from '../search-input';




import { MachineNavItem2 } from './machine-navitem2';



import { useMachineData } from '../../hooks/useMachineData';
import { useTitleInfo } from '../../hooks/useTitleInfo';
import { useNavigate } from 'react-router-dom';

interface MachineListProps {

  activeAssetID: string | null;
  onClickAsset: (assetID: string) => void;
  severityFilter: number | null;

}



const MachineList: React.FC<MachineListProps> = ({ activeAssetID, onClickAsset, severityFilter }) => {


  const [filterString, setFilterString] = useState<string>("");
  const onSearchChange = (value: string) => { setFilterString(value) };

  const { filteredAssetsByType, displayMachines, filteredAssets } = useMachineData();
  const { typeID, assetID } = useParams();

  const navigate = useNavigate();
  useEffect(() => {

    const assetIDs = filteredAssetsByType.get(typeID ?? "");
    let assetList = new Array();

    const regex = new RegExp(filterString, 'i'); // 'i' steht für case-insensitive

    console.log("regex", regex)
    assetIDs?.forEach(element => {
      const helper = displayMachines?.get(element);
      if (helper && regex.test(helper.Name)) {
        assetList.push(helper);
      }
    });


    if (typeof (severityFilter) == 'number') {
      let filteredList = assetList;


      if (severityFilter === 0) {
        //optimal
        filteredList = assetList.filter(asset => asset.Health > 7);
      } else if (severityFilter === 1) {
        //normal
        filteredList = assetList.filter(asset => asset.Health > 5 && asset.Health <= 7);
      } else if (severityFilter === 2) {
        //pay attention
        filteredList = assetList.filter(asset => asset.Health > 1 && asset.Health <= 5);
      } else if (severityFilter === 3) {
        //warning
        filteredList = assetList.filter(asset => asset.Health <= 1);
      }


      assetList = filteredList;
    }

    assetList.sort((a, b) => {
      if (a.Health === null) return 1;
      if (b.Health === null) return -1;
      return a.Health - b.Health;
    });

    setAssetsToDisplay(assetList);

  }, [typeID, assetID, filterString, filteredAssetsByType])



  const [assetsToDisplay, setAssetsToDisplay] = useState<DisplayMachineType[]>([]);
  useEffect(() => {



  }, [filteredAssetsByType, typeID]);






  const handleItemActivated = (key: string) => {
    if (onClickAsset) { onClickAsset(key) }
  }

  return (
    <div style={{ minWidth: "350px" }}>


      <SearchInput placeholder="Search here for assets..." handleChange={onSearchChange} handleSubmit={onSearchChange} deleteInputAfterSubmit={false} style={{ marginBottom: "0.5rem" }} />

      {assetsToDisplay && assetsToDisplay.map((asset, index) => {
        return (<MachineNavItem2
          key={asset.ID}
          item={asset}
          active={asset.ID === activeAssetID}
          style={{ marginBottom: "0.2rem" }}
          onClick={() => { handleItemActivated(asset.ID) }} />)
      })}

    </div>
  )
};



export { MachineList }

