import { IxIcon, IxIconButton, IxInputGroup } from '@siemens/ix-react'
import React, { useState } from 'react'
import { ISearchInputPropTypes } from './SearchInputPropTypes'

const SearchInput = ({
  placeholder = '',
  handleSubmit,
  handleChange,
  handleOnFocus,
  handleOnBlur,

  deleteInputAfterSubmit,
  style
}: ISearchInputPropTypes) => {
  const [inputValue, setInputValue] = useState('')

  function reset() {
    setInputValue('')
    handleBlur();
  }



  const handleFocus = () => {
    handleOnFocus && handleOnFocus(null)
  }
  const handleBlur = () => {
    // handleOnBlur && handleOnBlur(null)
  }





  return (
    <div style={style}>
      <form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault()
          if (handleSubmit) {
            try {
              handleSubmit(inputValue)
            } catch (e) {
              console.log(e)
            } finally {
              if (deleteInputAfterSubmit) {
                reset();
              }
            }
          }
        }}
      >
        <IxInputGroup
          onFocus={handleFocus}
          onBlur={handleBlur} >

          <span slot="input-start">
            <IxIcon name="search" size="16"></IxIcon>
          </span>
          <input
            id="input-string"
            type="string"
            className="form-control"
            onChange={(e) => {
              setInputValue(e.target.value)
              handleChange(e.target.value)
            }}
            value={inputValue}
            placeholder={placeholder}
          />
          <span slot="input-end">
            <IxIconButton
              onClick={() => {
                reset()
                handleChange('')
              }}
              id="clear-button"
              icon="clear"
              ghost
              size="16"
              style={{ display: 'block' }}
            ></IxIconButton>
          </span>
        </IxInputGroup>
      </form>
    </div >
  )
}

export { SearchInput }
