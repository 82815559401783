import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from '../pages/home'
import { IAppProps } from '../../App'


import { MachineOwnerDashboard } from '../pages/machine-owner-dashboard'
import { MainDashboard } from '../pages/main-dashboard'


import { UnAuthorized } from '../pages/un-authorized/UnAuthorized'
import { Error } from '../pages/error-page/Error'
import { EvalAuth } from '../pages/loginPage/EvalAuth'
import { MachineView } from '../pages/machine-view'


function AppRoutes({ logout }: IAppProps): React.ReactElement {
  return (
    <Routes>
      <Route path="/" element={<Home logout={logout} />}>
        <Route index element={<MainDashboard />} />
        <Route path="*" element={<MainDashboard />} />
        <Route path="unAuthorized" element={<UnAuthorized />} />
        <Route path="error" element={<Error />} />
        <Route path="evalauth" element={<EvalAuth />} />
        <Route element={<Error />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes

/*  <Routes>
      <Route path="/" element={<Home logout={logout} />}>
        <Route index element={<MainDashboard />} />


        <Route path="unAuthorized" element={<UnAuthorized />} />
        <Route path="error" element={<Error />} />
        <Route path="evalauth" element={<EvalAuth />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
    */