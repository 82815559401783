import { useAuth } from "../hooks/useAuth";
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react'


interface WhoAmIData {
  companyID: string;
  companyname: string;
}

const doWhoAmIRequest = async (token: string): Promise<null | WhoAmIData> => {

  try {

    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/WhoAmI`;
    //const apiUrl = `https://apimakscluster.azure-api.net/cs/mb_pickai/V1/whoami`;
    const apiUrl = `https://apimakscluster.azure-api.net/core/V1/whoami`;


    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<WhoAmIData> = await axios.get(apiUrl, { headers });

    if (response.status === 200) {
      return response.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);
  }

  return null;

}

//export { doWhoAmIRequest }
export type { WhoAmIData }





const useWhoAmI = () => {
  const [WhoAmI, setWhoAmI] = useState<null | WhoAmIData>(null);
  const auth = useAuth();

  useEffect(() => {
    doWhoAmIRequest(auth.getAuth()).then((result) => {
      setWhoAmI(result);
    });

  }, [])

  return { WhoAmI };
}
export { useWhoAmI }
