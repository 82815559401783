import { UnAuthorizedContainerStyle } from './UnAuthorized.css'

const UnAuthorized = () => {
  return (
    <div className={UnAuthorizedContainerStyle}>
      <h3 data-testid="test-UnAuthHeaderContainer">You are not authorized to see the page.</h3>
    </div>
  )
}

export { UnAuthorized }
