import React, { useEffect, useState } from 'react'
import { Navigation } from '../../atoms/navigation'
import { ReactComponent as SiemensLogo } from '../../assets/Siemens.svg'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { showModal } from '@siemens/ix-react'
import CustomModal from '../../atoms/customModal/CustomModal'
import { IHomePropTypes } from './HomeProptypes'
import {
  DefaultHomeContainerStyle,
  HomeLoaderStyle,
  breadCrumbContainerStyle,
} from './Home.css'
import { Breadcrumb } from '../../atoms/breadcrumb/Breadcrumb'

import { navigationItems } from './NavItemList'

import { UnAuthorized } from '../un-authorized/UnAuthorized'

import { Loader } from '../../atoms/loader'






import { useWhoAmI } from '../../requests/WhoAmIRequest'















function Home({ logout }: IHomePropTypes): React.ReactElement {

  const [userName, setUserName] = useState<string | undefined>();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [companyName, setCompanyName] = useState<string | null>(null);



  const { WhoAmI } = useWhoAmI();
  useEffect(() => {
    if (WhoAmI) {
      setCompanyName(WhoAmI.companyname ? " | " + WhoAmI.companyname : "");
    }
  }, [WhoAmI]);
















  /**
   * to call logout functionality of Azure
   */
  async function show(): Promise<void> {

    await showModal({
      title: 'test',
      content: <CustomModal logout={logout} />,
      centered: true,
      size: '360',
    })
  }



  if (!WhoAmI) return (<Loader className={HomeLoaderStyle} size={'large'} />);
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Navigation
        logo={<SiemensLogo />}
        menuItems={navigationItems(navigate, show, pathname, userName)}
        applicationName={"Production Healthiness" + companyName}
        logout={logout}
      >
        <div style={{ margin: "1rem" }}><Outlet /></div>
      </Navigation>
    </div >
  )
}

export { Home }


