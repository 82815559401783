
import React, { createContext, useContext, useState } from 'react';
import { AuthProviderProps } from './useAuthTypes';





// Define the type for the context value
/*
type AuthContextValue = {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  // Add other properties or methods related to authentication if needed
};
*/


type AuthContextValue = {
  setAuth: (token: string) => void;
  getAuth: () => string;
  deleteAuth(): void;
};



// Create the context with an initial value (you can customize the initial state as needed)
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

// Create a custom hook to access the AuthContext
export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider component that wraps your app and provides the context value
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // Example: Initialize state for isAuthenticated and setIsAuthenticated
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [Token, setToken] = useState<string>("");


  const deleteAuth = (): void => {
    setIsAuthenticated(false);
    setToken("");
  }

  const setAuth = (token: string): void => {

    (token === "" || !token) ? console.log("logged out") : console.log("logged in");
    console.log("Is authenticated" + isAuthenticated);
    setIsAuthenticated(token === "" || !token ? false : true);
    setToken(token);
  }


  const getAuth = (): string => {
    return Token;
  }


  // You can add other authentication-related state and methods here
  const authContextValue: AuthContextValue = { setAuth, getAuth, deleteAuth };


  // Return the context provider with the children as its content
  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
export { AuthProvider };
