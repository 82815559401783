//import axios from 'axios'
//import { authProvider } from '../../authConfig'

import { Notification } from "../hooks/use-notification-overview/useNotificationOverviewHookTypes";
import { Machine, MachineAdditionalInfo, Machines, SyncEvent, HealthData } from "../hooks/use-machine/useMachineHookTypes";
import { Request } from "../hooks/use-service-request/useServiceRequestHookTypes";




import { useAuth } from "../hooks/useAuth";
import axios, { AxiosResponse } from 'axios';


const useGetToken = async () => {
  let authToken = "";//todo get token from store;

  const au = useAuth();
  authToken = au.getAuth();

  return authToken;

}

const useGetAssets = async (): Promise<number[]> => {


  const token = useGetToken();

  if (token) {
    console.log("doing mocked backend request with token: " + token);
  }

  return [1, 2, 3, 4, 5];
}

export { useGetAssets }



/**
 * Example Data
 */

const notifications = [
  { title: 'Warning: Service needed', id: 'SIE-7883-3', location: 'MyPlant > Hall1', date: '2022-11-05', time: '08:51:21', type: 'Warning' },
  { title: 'Info: Onboarding successful', id: 'SIE-4456-6', location: 'MyPlant > Hall3', date: '2022-11-05', time: '08:51:21', type: 'Info' },
  { title: 'Info: Service requested', id: 'SIE-321-62', location: 'MyPlant > Production Line2', date: '2022-11-05', time: '08:51:21', type: 'Info' },
]


const generalInformations = [
  {
    type: 'SIGENTICS Generator', id: 'SIE-7883-3', onboarding: '24.03.2020', runtime: 2450, health: 'Service', firmware: 'V1.342.895', status: 'Service Needed'
  },
  {
    type: 'SIMATIC R2-600', id: 'SIE-4456-6', onboarding: '24.05.2018', runtime: 453, health: 'Good', firmware: 'V1.32425', status: 'Good'
  },
  {
    type: 'SITOP 700', id: 'SIE-321-62', onboarding: '01.05.2022', runtime: 5675, health: 'Good', firmware: 'V1.1231.1231', status: 'Good'
  },
]

const tasks = [
  { description: 'Visual Inspection', done: true }, { description: 'Software Update', done: true }, { description: 'Oil Change', done: false }, { description: 'Spare Parts', done: true }, { description: 'Belt Change', done: false }, { description: 'Corrosion Check', done: false }
]


const serviceInformation = [
  {
    description: 'Damage Repair',
    date: '01.01.2022',
    type: 'SIGENTICS Generator',
    expert: 'John Doe',
    id: 'SIE-7883-3',
    additional: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    documentation: 'Service documentation unavailable',
    tasks: tasks,
  },
  {
    description: 'Damage Repair',
    date: '21.12.2021',
    type: 'SITOP PSU',
    expert: 'John Doe',
    id: 'SIE-7883-3',
    additional: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    documentation: 'Service documentation unavailable',
    tasks: tasks,
  },
]

let machines = [
  {
    generalInformation: generalInformations[0],
    serviceInformation: serviceInformation
  },
  {
    generalInformation: generalInformations[1],
    serviceInformation: serviceInformation
  },
  {
    generalInformation: generalInformations[2],
    serviceInformation: serviceInformation
  }
]


let requests = [
  {
    generalInformation:
    {
      company: 'Example Company',
      type: 'SIGENTICS Generator',
      id: '1234',
      lastService: '17.05.2022'
    },
    contactInformation:
    {
      contactPerson: 'John Doe',
      email: 'john.doe@example.com',
      telephone: '(+49) 123 456789',
      additionalInformation: 'Please service on weekends.'
    }
  }
]


const getNotifications = async (): Promise<Notification[]> => {

  const token = "abc";
  //const token = getToken();

  if (token) {
    console.log("doing mocked backend request with token: " + token);
  }

  return notifications;
}
export { getNotifications }




const getMachineByID = async (token: string, ID: string): Promise<null | Machine> => {

  try {

    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Assets/${ID}`;
    const apiUrl = `https://apimakscluster.azure-api.net/core/V1/assets/${ID}`;



    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<Machine> = await axios.get(apiUrl, { headers });

    if (response.status === 200) {
      response.data.generalInformation = generalInformations[0],
        response.data.serviceInformation = new Array(serviceInformation[0], serviceInformation[1], serviceInformation[0], serviceInformation[1], serviceInformation[0], serviceInformation[1], serviceInformation[0]);
      return response.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;

}
export { getMachineByID }



const getMachineAdditionalInfo = async (token: string, ID: string): Promise<null | MachineAdditionalInfo> => {
  try {

    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Query?AssetID=${ID}&Metric=69111c3f-fbf5-4e91-9135-d6ac6d872165`;
    const apiUrl = `https://apimakscluster.azure-api.net/core/V1/query?AssetID=${ID}&MetricID=69111c3f-fbf5-4e91-9135-d6ac6d872165`;

    const headers = {
      Authorization: token,
    };


    //const response: AxiosResponse<{ data: MachineAdditionalInfo[] }> = await axios.get(apiUrl, { headers });
    const response: AxiosResponse<{ data: MachineAdditionalInfo }> = await axios.get(apiUrl, { headers });


    if (response.status === 200) {
      //return response.data.data[0];
      return response.data.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getMachineAdditionalInfo }



const getAllMachines = async (token: string): Promise<null | Machine[]> => {


  try {

    //const apiUrl = 'https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Assets';
    const apiUrl = 'https://apimakscluster.azure-api.net/core/V1/assets';


    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<Machine[]> = await axios.get(apiUrl, { headers });

    if (response.status === 200) {
      return response.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getAllMachines }


const getSyncEvents = async (token: string, ID: string): Promise<null | SyncEvent[]> => {


  try {



    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Query?AssetID=${ID}&Metric=475be7f5-7e28-4a44-8fba-22492a2f7442`;
    const apiUrl = `https://apimakscluster.azure-api.net/cs/mb_pickai/V1/query?AssetID=${ID}&MetricID=475be7f5-7e28-4a44-8fba-22492a2f7442`;

    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<{ data: SyncEvent[] }> = await axios.get(apiUrl, { headers });

    if (response.status === 200) {
      return response.data.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getSyncEvents }

const getServiceRequests = async (): Promise<Request[]> => {

  const token = "abc";
  //const token = getToken();

  if (token) {
    console.log("doing mocked backend request with token: " + token);
  }

  return requests;
}
export { getServiceRequests }



const getServiceRequestByID = async (ID: string): Promise<Request> => {

  const token = "abc";
  //const token = getToken();

  if (token) {
    console.log("doing mocked backend request with token: " + token);
  }

  let requestWithID = null;

  requests.forEach((request) => {
    if (request.generalInformation.id === ID) requestWithID = request;
  })

  return requestWithID ? requestWithID : requests[0];
}
export { getServiceRequestByID }



const getDownloadFile = async (token: string, AssetID: string, SyncEvent: string, DownloadKey: string): Promise<null | AxiosResponse> => {

  try {
    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Customer/FlexGrasp/V1/Download?AssetID=${AssetID}&MetricID=475be7f5-7e28-4a44-8fba-22492a2f7442&SyncEvent=${SyncEvent}&DownloadKey=${DownloadKey}`;
    const apiUrl = ` https://apimakscluster.azure-api.net/cs/mb_pickai/V1/download?AssetID=${AssetID}&MetricID=88fdfe8c-1003-4dab-8b3e-67182137e25b&Downloadkey=${DownloadKey}`;
    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse = await axios.get(apiUrl, { headers, responseType: 'blob' });

    if (response.status === 200) {
      return response
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getDownloadFile }


const getHealthState = async (token: string, AssetID: string): Promise<null | HealthData> => {

  try {
    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Customer/FlexGrasp/V1/Download?AssetID=${AssetID}&MetricID=475be7f5-7e28-4a44-8fba-22492a2f7442&SyncEvent=${SyncEvent}&DownloadKey=${DownloadKey}`;
    const apiUrl = ` https://apimakscluster.azure-api.net/core/V1/query?AssetID=${AssetID}&MetricID=6eec7dce-c4ef-4090-bece-6987a57a40c3`;
    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<{ data: HealthData }> = await axios.get(apiUrl, { headers });


    if (response.status === 200) {
      return response.data.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getHealthState }




const getMachineState = async (token: string, AssetID: string): Promise<string | null> => {

  try {
    const apiUrl = `https://apimakscluster.azure-api.net/core/V1/query?AssetID=${AssetID}&MetricID=9158b5ea-7044-4390-aeb7-29b2ef47d53b`;
    const headers = {
      Authorization: token,
    };

    const response: AxiosResponse<{ data: { timestamp: string, value: string } }> = await axios.get(apiUrl, { headers });

    if (response.status === 200) {
      return response.data.data.value;
    } else {
      return null; // Wenn der Statuscode nicht 200 ist, gib null zurück
    }
  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);
    return null; // Bei einem Fehler gib ebenfalls null zurück
  }
};

export { getMachineState };





const getQuery = async (token: string, AssetID: string, MetricID: string, latestValue?: boolean, From?: Date, To?: Date): Promise<null | any> => {

  try {
    //const apiUrl = `https://p352cwyzq2.execute-api.eu-central-1.amazonaws.com/Customer/FlexGrasp/V1/Download?AssetID=${AssetID}&MetricID=475be7f5-7e28-4a44-8fba-22492a2f7442&SyncEvent=${SyncEvent}&DownloadKey=${DownloadKey}`;
    let apiUrl = `https://apimakscluster.azure-api.net/core/V1/query?AssetID=${AssetID}&MetricID=${MetricID}`;


    // Füge optionale Parameter hinzu
    if (From !== undefined) { apiUrl += `&From=${From.toISOString()}`; }
    if (To !== undefined) { apiUrl += `&To=${To.toISOString()}`; }
    if (latestValue !== undefined && latestValue) {
      apiUrl += `&LatestValue`;
    }


    const headers = {
      Authorization: token,
    };


    const response: AxiosResponse<{ data: any }> = await axios.get(apiUrl, { headers });


    if (response.status === 200) {
      return response.data.data;
    }

  } catch (error) {
    console.error('Fehler beim Datenabruf:', error);

  }

  return null;
}
export { getQuery }