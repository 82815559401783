import React, { useEffect, useState } from 'react';
import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';
import { useMachineData } from '../../hooks/useMachineData';


import { IxTile, IxTypography } from '@siemens/ix-react';
import { useQuery } from '../../hooks/use-machine/useMachine';


import _ from 'lodash';


interface CoaterHealthProps {
  assetID: string | null,
}


const METRIC_COATINGTHIKNESS_OUTLIERPERMONTH = "9881a23f-14ea-44fc-95d7-a86d590be868";
const METRIC_COATINGTHIKNESS_OUTLIERPERDAY = "d8609085-9380-46eb-af83-78502de0e671";


const SeverityColors: Map<number, string> = new Map();
SeverityColors.set(-1, "red"); //optimal
SeverityColors.set(0, "#01893A"); //optimal
SeverityColors.set(1, "#909700"); //normal
SeverityColors.set(2, "#E96401"); //payattention
SeverityColors.set(3, "#ED2005"); //warning




const CoaterHealth: React.FC<CoaterHealthProps> = ({ assetID }) => {

  const { displayMachines } = useMachineData();
  const { QueryData } = useQuery();

  const [asset, setAsset] = useState<DisplayMachineType | null>(null);
  const [displayValues, setDisplayValus] = useState<any>({
    HealthIndicators: {
      METRIC_COATINGTHIKNESS_OUTLIERPERMONTH: undefined,
      METRIC_COATINGTHIKNESS_OUTLIERPERDAY: undefined
    }
  })

  useEffect(() => {

    setDisplayValus(
      {
        HealthIndicators: {
          METRIC_COATINGTHIKNESS_OUTLIERPERMONTH: undefined,
          METRIC_COATINGTHIKNESS_OUTLIERPERDAY: undefined
        }
      })

    setAsset(displayMachines?.get(assetID ?? "") ?? null);
  }, [displayMachines, assetID]);


  const mapHealthToString = (health: number): string => {

    let value = "unknown";
    if (health == 0) value = "Optimal";
    if (health == 1) value = "Normal";
    if (health == 2) value = "Pay Attention";
    if (health == 3) value = "Warning";
    return value;
  }


  const getHealthIndicators = async () => {
    if (asset) {


      const updatedDisplayValues = _.cloneDeep(displayValues) || {};

      //update METRIC_COATINGTHIKNESS_OUTLIERPERDAY if not exist
      if (typeof (updatedDisplayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH) === "undefined") {
        let value = await QueryData(asset.ID, METRIC_COATINGTHIKNESS_OUTLIERPERMONTH, true);
        updatedDisplayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH = value !== null ? value[0] : undefined;



      }


      //update  METRIC_COATINGTHIKNESS_OUTLIERPERDAY if not exist
      if (typeof (updatedDisplayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY) === "undefined") {
        let value = await QueryData(asset.ID, METRIC_COATINGTHIKNESS_OUTLIERPERDAY, true);
        updatedDisplayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY = value !== null ? value[0] : undefined;


      }


      setDisplayValus(updatedDisplayValues);


    }
  }
  useEffect(() => { getHealthIndicators(); }, [asset]);

  useEffect(() => {
    //  console.log("Changed", displayValues, [displayValues])
  })

  useEffect(() => {
    assetID && console.log("AssetID: ", assetID);
  }, [assetID])

  return (


    <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem" }}>


      {/**Tile 1  Persistent Irregular Coating thickness*/}
      <div style={{ minWidth: "400px", maxWidth: "500px", background: "#F3F3F0", padding: "2rem" }}>



        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health) != 'undefined') ? (

            <div style={{
              display: "inline-block",
              padding: "0.2rem",
              paddingLeft: "0.8rem",
              paddingRight: "0.8rem",
              background: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health),
              color: "white"
            }}>
              <IxTypography format="code">{mapHealthToString(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health)}</IxTypography></div>

          ) :
            (
              <div style={{ display: "inline-block", padding: "0.2rem", paddingLeft: "0.8rem", paddingRight: "0.8rem", background: "gray", color: "white" }}><IxTypography format="code">Loading</IxTypography></div>

            )}

        <div style={{ flex: "1", marginBottom: "2rem" }}><IxTypography format="h2">Percentage of out-of-spec coating thickness in a day</IxTypography></div>




        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.outlierpercentage) != 'undefined') &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health) != 'undefined') ? (
            <>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1 }}>
                  <IxTypography format="display-sm">Value</IxTypography>
                </div>
                <div style={{}}>
                  <IxTypography format="display-sm">
                    {displayValues?.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.outlierpercentage.toFixed(1)}%
                  </IxTypography>
                </div>
              </div>

              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1, color: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health) }}>
                  <IxTypography format="display-sm">Risk Level</IxTypography>
                </div>
                <div style={{ color: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health) }}>
                  <IxTypography format="display-sm">{mapHealthToString(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERDAY.health)}</IxTypography>

                </div>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>Loading ...</div>
          )}





        <div style={{ marginTop: "1.5rem" }}>
          <IxTypography format="display-sm">Indicator</IxTypography>
          <IxTypography format="display-xs">Persistent irregular coating thickness</IxTypography>
        </div>
      </div>




      {/**Tile 2 Persistent Irregular Coating thickness*/}
      <div style={{ minWidth: "400px", maxWidth: "500px", background: "#F3F3F0", padding: "2rem" }}>



        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health) != 'undefined') ? (
            <div style={{
              display: "inline-block",
              padding: "0.2rem",
              paddingLeft: "0.8rem",
              paddingRight: "0.8rem",
              background: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health),
              color: "white"
            }}>
              <IxTypography format="code">{mapHealthToString(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health)}</IxTypography></div>

          ) :
            (
              <div style={{ display: "inline-block", padding: "0.2rem", paddingLeft: "0.8rem", paddingRight: "0.8rem", background: "gray", color: "white" }}><IxTypography format="code">Loading</IxTypography></div>

            )}

        <div style={{ flex: "1", marginBottom: "2rem" }}><IxTypography format="h2">Coating Profile Standard Deviation Trend</IxTypography></div>





        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH &&
            displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.outlierpercentage &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.standarddevtrend) != 'undefined') &&
            (typeof (displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health) != 'undefined') ? (

            <>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1 }}>
                  <IxTypography format="display-sm">Value</IxTypography>
                </div>
                <div style={{}}>
                  <IxTypography format="display-sm">
                    {displayValues?.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.standarddevtrend.toFixed(1)}%
                  </IxTypography>
                </div>
              </div>

              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1, color: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health) }}>
                  <IxTypography format="display-sm">Risk Level</IxTypography>
                </div>
                <div style={{ color: SeverityColors.get(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health) }}>
                  <IxTypography format="display-sm">{mapHealthToString(displayValues.HealthIndicators.METRIC_COATINGTHIKNESS_OUTLIERPERMONTH.health)}</IxTypography>

                </div>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>Loading ...</div>
          )}





        <div style={{ marginTop: "1.5rem" }}>
          <IxTypography format="display-sm">Indicator</IxTypography>
          <IxTypography format="display-xs">Persistent irregular coating thickness</IxTypography>
        </div>
      </div>


    </div >
  );

}

export { CoaterHealth };