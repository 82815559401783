import React, { useEffect, useState } from 'react';
import { DisplayMachineType } from '../../displaytypes/displaytypes';
import { CSSProperties } from 'react';
import { IxPill, IxTypography, IxIcon } from '@siemens/ix-react';
import { greyBackground } from '../../pages/machine-owner-dashboard/MachineOwnerDashboard.css';



interface MachineNavItemProps {
  item: DisplayMachineType | null | undefined,
  active?: boolean,
  onClick?: () => void,
  style?: CSSProperties,

}

const getMachineTypeString = (item: string): string => {
  return "Machine";
}

const resolveActiveCircleColor = (value: string | null): string => {
  let result = "rgba(128, 128, 128, 0.5)";
  if (value && value.toLowerCase() === "running") { result = "green"; }
  if (value && value.toLowerCase() === "inactive") { result = "yellow"; }

  return result;
};

const MachineNavItem2: React.FC<MachineNavItemProps> = ({ item, active, style, onClick }) => {

  const SeverityColors: Map<number, string> = new Map();
  SeverityColors.set(-1, "red"); //optimal
  SeverityColors.set(0, "#01893A"); //optimal
  SeverityColors.set(1, "#909700"); //normal
  SeverityColors.set(2, "#E96401"); //payattention
  SeverityColors.set(3, "#ED2005"); //warning



  const [healthIndicator, setHealthIndicator] = useState<number>(-1)

  useEffect(() => {
    if (item?.Health) {
      if (item.Health <= 2.5) setHealthIndicator(3);
      else if (item.Health <= 5) setHealthIndicator(2);
      else if (item.Health <= 7.5) setHealthIndicator(1);
      else setHealthIndicator(0);
    }
    else setHealthIndicator(-1);

  }, [item])

  const handleOnClick = () => {
    if (onClick) { onClick() }
  }


  if (!item) return null;
  return (
    <div style={{
      ...style,
      borderRadius: "4px",
      border: active ? "1px solid #005159" : "0px solid #E5E5E5",
      background: "#F3F3F0", // Rot, wenn active true ist, sonst Weiß
      padding: "0.5rem",
      position: "relative" // Position relativ für die absolute Positionierung des Balkens
    }}
      onClick={handleOnClick}
    >

      {/**Header section */}
      <div style={{ display: 'flex', alignItems: "center" }}>

        <div style={{ flex: 1, alignItems: "top" }}>

          <div style={{ display: "flex" }}>
            <IxIcon name="controller-device" />
            <div>
              <IxTypography format="display-sm">{item.Name}</IxTypography>

              <div style={{ display: 'flex', marginTop: "0.5rem", gap: "0.3rem" }}>
                <IxTypography format="body-sm">Status</IxTypography>
                <IxTypography format="body-sm">-</IxTypography>
                <IxTypography format="body-sm"> {item.State || "unknown"} </IxTypography>
              </div>
            </div>
          </div>

        </div>

        {/**Severity indicator */}
        <div style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          width: "8px",
          background: SeverityColors.get(healthIndicator)
        }} />

        <div>
          <IxIcon name="chevron-right" />
        </div>

      </div>









    </div >
  )
}



export { MachineNavItem2 }

/*
  
<IxTypography style={{ marginTop: "0.5rem" }} format="body-sm">{item.Description}</IxTypography>















  */