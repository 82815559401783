import React, { useEffect } from "react";
import axios, { AxiosRequestConfig } from 'axios';
import { /*Navigate,*/ useNavigate, useSearchParams } from "react-router-dom";
import qs from "qs";
import { useAuth } from "../../hooks/useAuth";


//import process from "process";



//declare function alert(message: string): void;




/*function storeAuth(response, setToken) {
  //sessionStorage.setItem("isAuth", true);
  //sessionStorage.setItem("id_token", response.data.id_token);
  //sessionStorage.setItem("access_token", response.data.access_token);
  //sessionStorage.setItem("refresh_token", response.data.refresh_token);
  //sessionStorage.setItem("expires_in", response.data.expires_in);
  //setToken({ token: response.data.access_token });
  console.log("auth stored");
}
*/

let _redirect_uri: string = "";
if (process.env.REACT_APP_STAGE === "rel") {
  _redirect_uri = "https://rm.prodhealth.az.tpoc.datadrivenx.com//evalauth";
}
if (process.env.REACT_APP_STAGE === "debug") {
  _redirect_uri = "http://localhost:3000/evalauth";
}




function EvalAuth(): React.ReactElement {
  //const { setIsAuthenticated } = useAuth();
  const { setAuth } = useAuth();


  const navigate = useNavigate();



  const [searchParams] = useSearchParams();
  const thecode: string | null = searchParams.get("code");



  let count: number = parseInt(sessionStorage.getItem("counter") || "0");
  count++;
  sessionStorage.setItem("counter", count.toString());




  useEffect(() => {


    if (!thecode) {
      navigate("/error", { state: { errorMessage: "no code" } });
      return;
    }

    const fetchToken = async (options: AxiosRequestConfig): Promise<void> => {
      try {

        const response = await axios(options);

        setAuth("Bearer " + response.data.access_token);
        navigate("/");
      } catch (e) {
        navigate("/error");
      }
    };

    //let url: string = "https://demoddx.auth.eu-central-1.amazoncognito.com/oauth2/token";
    let url: string = "https://siemens-qa-00432.eu.auth0.com/oauth/token";


    const data = {
      grant_type: "authorization_code",
      code: thecode,
      client_id: "Q9HbFuJUowH2EQ2n5aeBS7lsa8XMy8iH",
      redirect_uri: _redirect_uri,
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url,
    };

    fetchToken(options);


  }, [])


  return (<div></div>);


}


export { EvalAuth };
