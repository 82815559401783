import React, { ReactElement, useEffect, useRef, useState, ChangeEvent } from 'react'




import { useAllMachines } from '../../hooks/use-machine/useMachine'

import { useAuth } from '../../hooks/useAuth'
import { IxBlind, IxContentHeader } from '@siemens/ix-react';
import { BreadCrumpNavigation, MachineList } from '../../atoms/machine-navitem'

import { DisplayMachineType } from '../../displaytypes/displaytypes';
import { getDisplayMachines } from '../../utils/APIHelper';

import { IxBreadcrumb, IxBreadcrumbItem } from '@siemens/ix-react';

import { useNavigate, Outlet } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom'
type DisplayMachinesMap = Map<string, DisplayMachineType>;

import { useParams } from 'react-router-dom';
import { MachineTypeOverview } from '../../components/machinetype-overview';


import { useMachineData } from '../../hooks/useMachineData';
import { TitleInfoProvider, useTitleInfo } from '../../hooks/useTitleInfo';

import { createBrowserHistory } from 'history';




function MainDashboard(): ReactElement {

  const auth = useAuth();
  const { displayMachines, setDisplayMachines } = useMachineData();
  const { filteredAssetsByType } = useMachineData();

  const { title } = useTitleInfo();


  const navigate = useNavigate();

  const { machines } = useAllMachines();

  const updateMachineList = async () => {
    const helper = await getDisplayMachines(auth.getAuth(), machines, displayMachines);
    setDisplayMachines(helper);
  }
  useEffect(() => { updateMachineList(); }, [machines]);



  //const [breadCrumps, setBreadCrumps] = useState<string[]>(["Global"])
  const { breadCrumps, setBreadCrumps } = useMachineData();
  const [navigationLayer, setNavigationLayer] = useState<number>(0);
  useEffect(() => {
    const helper = Array.from(breadCrumps);
    const newArray = helper.slice(0, navigationLayer + 1);
    setBreadCrumps(newArray);
  }, [navigationLayer]);


  const handleClickOnAsset = (assetID: string) => { /*navigate(`/health/${assetID}`) */ };
  const handleClickOnFolder = (folder: string[]) => {
    //let number = folder.length - 1;
    //setNavigationLayer(number);
    //setBreadCrumps(folder)
  };


  const handleLayerClick = (layer: string[]) => {
    //navigate("/");
    // setBreadCrumps(layer);
  }




  const displayHeader = () => {

    const history = createBrowserHistory();

    const text = title?.title ?? "Production Dashboard";
    const zeroPath = (history.location.pathname == '/')

    const handleOnClick = () => {
      if (!zeroPath) {
        if (title?.backLink) {
          navigate(title.backLink);
        }
        else {
          history.back();
        }
      }
    }

    return (
      <IxContentHeader
        hasBackButton={title?.hasBackButton && !zeroPath ? true : undefined}
        headerTitle={text}
        onBackButtonClick={handleOnClick}
      />)

  }


  useEffect(() => {
    console.log("filteredAssets", filteredAssetsByType?.size)
  }, [filteredAssetsByType])

  return (
    <div>

      {displayHeader()}


      <BreadCrumpNavigation onLayerChanged={handleLayerClick} />
      <MachineTypeOverview />


    </div >

  );


}

export { MainDashboard }

