import React, { useState, useRef, useEffect } from 'react';
import { IxBreadcrumb, IxBreadcrumbItem, IxTypography } from '@siemens/ix-react';
import { SearchInput } from '../search-input';

import { DisplayMachinesMap, DisplayMachineType } from '../../displaytypes/displaytypes';
import Loki from 'lokijs';

import { IxChip, IxContentHeader, IxDrawer, IxIcon } from '@siemens/ix-react';
import { marginTop } from '../donut/constants';

import { useMachineData } from '../../hooks/useMachineData';

interface BreadCrumpNavigationProps {
  /*items: DisplayMachinesMap | null | undefined;*/
  onLayerChanged?: (layers: string[]) => void;
}




const BreadCrumpNavigation: React.FC<BreadCrumpNavigationProps> = ({ onLayerChanged }) => {


  const { displayMachines: items } = useMachineData();
  const { breadCrumps, setBreadCrumps } = useMachineData();
  const { setFilteredAssets } = useMachineData();




  const breadCrumpRef = useRef<HTMLDivElement>(null);


  //save suggestions for the current navigationlayer
  const [folderSuggestions, setfolderSuggestions] = useState<{ Name: any; Path: any; }[]>([]);
  const [searchItems, setSearchItems] = useState<{ Icon: any | null; Name: any; Path: any; Type: number }[] | null>([]);




  //const [breadCrumps, setBreadCrumps] = useState<string[]>(["Global"])
  const [navigationLayer, setNavigationLayer] = useState<number>(0);




  const [assetsDB, setAssetsDB] = useState<any>(new Loki('lokijs_database'));

  //if items changed generally tha recreate loki database
  useEffect(() => {

    assetsDB.removeCollection('elements');
    assetsDB.removeCollection('assets');


    const collection = (assetsDB as Loki).addCollection<DisplayMachineType>('elements') as any;

    items?.forEach((value, key) => {
      const location = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath].join("@@") : "Global";
      collection.insert({ ID: value.ID, Location: location });
      processAvailableAssets(["Global"]);
    });


    const collectionAssets = (assetsDB as Loki).addCollection<DisplayMachineType>('assets') as any;

    items?.forEach((value, key) => {
      const location = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath].join("@@") : "Global";
      const searchString = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath, value.Name].join("@@") : "Global";
      collectionAssets.insert({ ID: value.ID, Location: location, SearchString: searchString, Name: value.Name });
    });


  }, [items]);





  const processAvailableAssets = (layers: string[]) => {
    if (!assetsDB) return;

    const collection = (assetsDB as Loki).getCollection('elements');
    if (!collection || !collection.data.length) return;

    let search = layers.join("@@");
    if (!search) return;


    const AvailableAssetsInCurrentLayer = collection.find({ Location: { $regex: new RegExp(`^${search}`) } });
    const AvailableAssetIDsInCurrentLayer = AvailableAssetsInCurrentLayer.map(item => item.ID);
    setFilteredAssets(AvailableAssetIDsInCurrentLayer);


    const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

    const folders = AvailableAssetsInDeeperLayers
      .map(item => item.Location.split("@@")) // Array aus dem Location-String erstellen
      .map(locationArray => locationArray.slice(0, layers.length + 1)) // Die Arrays entsprechend der Länge von layers oder layer zuschneiden
      .map(location => location.join("@@")) // Strings rekonstruieren, um Duplikate zu eliminieren
      .filter((value, index, self) => self.indexOf(value) === index) // Duplikate eliminieren
      .map(location => location.split("@@")) // Arrays aus den Location-Strings erstellen
      .map(locationList => ({ // Objekte für die Ordner erstellen
        Name: locationList[layers.length],
        Path: locationList.slice(0, layers.length + 1)
      }));

    setfolderSuggestions(folders);
  }






  useEffect(() => {
    const newArray = breadCrumps.slice(0, navigationLayer + 1);
    setBreadCrumps(newArray);
    processAvailableAssets(newArray);
  }, [navigationLayer]);



  useEffect(() => {
    onLayerChanged && onLayerChanged(breadCrumps)
  }, [breadCrumps])

  //click on a suggestion
  const handleClickSuggestion = (element: { Name: any; Path: any; }) => {
    setBreadCrumps(element.Path);
    setNavigationLayer(element.Path.length - 1);
  }


  const handleClickSearch = (element: { Icon: string | null; Name: any; Path: any; Type: number }) => {

    console.log("elaement search click ", element)
    if (element.Type == 1) {
      setBreadCrumps(element.Path); setNavigationLayer(element.Path.length - 1);
    }
  }








  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const onSearchChange = (value: string) => { processAvailableAssetsFromSearchFiler(value) };


  const findAllOccurrences = (value: string, search: string) => {
    const indices = [];
    const string = value.toLowerCase();
    let index = string.indexOf(search);
    while (index !== -1) {
      indices.push(index);
      index = string.indexOf(search, index + 1);
    }
    return indices;
  }




  const findAllLayers = (value: string, cutpos: number[]) => {
    const layers = cutpos.map(element => {
      const searchString = value.slice(0, element);
      const array = searchString.split("@@");
      return array.length;
    });

    return layers;
  }






  const processAvailableAssetsFromSearchFiler = (value: string) => {
    if (!assetsDB) return;
    if (!value || value.trim() === "") {
      setSearchItems(null);
      return;
    }




    const collection = (assetsDB as Loki).getCollection('assets');
    if (!collection || !collection.data.length) return;



    const AvailableAssets = collection.find({ SearchString: { $regex: new RegExp(`${value}`, "i") } });




    let folderresults: string[] = [];
    const filteredAssets = AvailableAssets.map(element => {



      let getLayers;
      const indices = findAllOccurrences(element.Location, value);
      if (indices.length && (getLayers = findAllLayers(element.Location, indices)).length) {
        const layered = getLayers.map(elem => {
          const splitLocation = element.Location.split("@@");
          return splitLocation.slice(0, elem);
        });
        folderresults = folderresults.concat(layered);
      }



      const locationString = element.Location.replace(/@@/g, " >> ");
      const truncatedLocation = locationString.length > 50 ? locationString.slice(0, 50) + "..." : locationString;

      return {
        Icon: "hardware-cabinet",
        Name: element.Name + " | " + truncatedLocation,
        Path: element.Location,
        Type: 0 //asset entry
      };
    });





    const uniqueFolderResults = folderresults.filter((arr, index) => {
      return index === folderresults.findIndex(innerArr => {
        return JSON.stringify(innerArr) === JSON.stringify(arr);
      });
    });

    console.log(uniqueFolderResults);
    const SearchItemsFolder = uniqueFolderResults.map(elem => {
      const nameString = Array.isArray(elem) ? elem.join(" >> ") : elem;

      return {
        Icon: "layers",
        Name: nameString,
        Path: elem,
        Type: 1 //folder entry
      }

    })


    setSearchItems([...SearchItemsFolder, ...filteredAssets]);




  }









  const onFocus = (e: any) => {
    setIsSearchFocused(true);
  }
  const onBlur = (e: any) => {
    setIsSearchFocused(false);
  }





  return (
    <div style={{ display: 'flex', marginBottom: '0.5rem', position: 'relative' }}>
      <div style={{ flex: 3 }}>

        <div ref={breadCrumpRef}>
          <IxBreadcrumb>
            {
              breadCrumps.map((element, index) => (
                <IxBreadcrumbItem key={index} icon={
                  index === 0 ? "globe" :
                    index === 1 ? "building1-filled" :
                      "layers"
                }
                  label={element} onClick={() => { setNavigationLayer(index); }} />
              ))
            }
          </IxBreadcrumb>
        </div>

      </div>
      <div style={{ position: 'relative', flex: 1 }} onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget) && (breadCrumpRef.current && !breadCrumpRef.current.contains(e.relatedTarget))) {
          onBlur(null);
        }
      }}>
        <SearchInput
          placeholder="Filter displayed assets here..."
          handleChange={onSearchChange}
          handleSubmit={onSearchChange}
          handleOnFocus={onFocus}
          handleOnBlur={onBlur}
          deleteInputAfterSubmit={false}

        />


        {isSearchFocused && (
          <div
            style={{
              marginTop: "-0.3rem",
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(243, 243, 240, 0.9)',
              zIndex: 999,
            }}

          >







            {folderSuggestions && folderSuggestions.length > 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Suggestions</IxTypography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {folderSuggestions.map((element, index) => (
                    <IxChip
                      icon="layers"
                      style={{ marginBottom: "0.5rem" }}
                      key={index}
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickSuggestion(element);
                      }}
                    >
                      {element.Name}
                    </IxChip>
                  ))}
                </div>
              </div>
            )}

            {folderSuggestions && folderSuggestions.length === 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Suggestions    ...no further suggestions found</IxTypography>


              </div>
            )}


            {searchItems && searchItems.length > 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Search results...</IxTypography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {searchItems.map((element, index) => (
                    <IxChip
                      icon={element.Icon || "layers"}
                      style={{ marginBottom: "0.5rem" }}
                      key={index}
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickSearch(element);
                      }}
                    >
                      {element.Name}
                    </IxChip>
                  ))}
                </div>
              </div>
            )}
          </div>

        )
        }

      </div >

    </div >
  );
};

export { BreadCrumpNavigation };

/*


  const processAvailableAssets = (layers?: string[] | undefined) => {
    if (assetsDB) {
      const collection = (assetsDB as Loki).getCollection('elements');

      let bColletionAvailable = (collection != null && collection.data.length);
      let search = null;
      if (layers) {
        search = layers.join("@@");
      }
      else {
        if (Array.isArray(layer) && layer.length) {
          search = layer.join("@@");
        }
      }


      if (bColletionAvailable && search) {

        //let search = layer.join("@@");

        const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

        //create folder list
        const folders = AvailableAssetsInDeeperLayers
          .map(item => item.Location)                                           //only process Location Entry       
          .map(location => location.split("@@"))                                //make array out of string
          .map(locationArray => locationArray.slice(0, layer.length + 1))       //cut arrays according to layer length
          .map(location => location.join("@@"))                                 //reconstruct string our of arrays to eliminate duplicates easy
          .filter((value, index, self) => self.indexOf(value) === index)      	//eliminate duplicates
          .map(location => location.split("@@"))                                //create back an array from location string
          .map(locationList => {                                                //create folder elements of the n+1 layer
            const name = locationList[layer.length];
            const path = locationList.slice(0, layer.length + 1);
            return { Name: name, Path: path };
          });


        setfolderSuggestions(folders);

      }
    }
  }

  */






















/*



import React, { useState, useRef, useEffect } from 'react';
import { IxBreadcrumb, IxBreadcrumbItem, IxTypography } from '@siemens/ix-react';
import { SearchInput } from '../search-input';

import { DisplayMachinesMap, DisplayMachineType } from '../../displaytypes/displaytypes';
import Loki from 'lokijs';

import { IxChip, IxContentHeader, IxDrawer, IxIcon } from '@siemens/ix-react';
import { marginTop } from '../donut/constants';

import { useMachineData } from '../../hooks/useMachineData';

interface BreadCrumpNavigationProps {
  items: DisplayMachinesMap | null | undefined;
  onLayerChanged?: (layers: string[]) => void;
}




const BreadCrumpNavigation: React.FC<BreadCrumpNavigationProps> = ({ items, onLayerChanged }) => {


  const { displayMachines: breads } = useMachineData();

  useEffect(() => {
    console.log("use effect breadcrum navigatione", JSON.stringify(breads));
  }, [breads])


  //if items changed generally tha recreate loki database
  useEffect(() => {

    console.log("use effect breadcrum navigatione on items", JSON.stringify(items));


  }, [items]);




  const breadCrumpRef = useRef<HTMLDivElement>(null);


  //save suggestions for the current navigationlayer
  const [folderSuggestions, setfolderSuggestions] = useState<{ Name: any; Path: any; }[]>([]);
  const [searchItems, setSearchItems] = useState<{ Icon: any | null; Name: any; Path: any; Type: number }[] | null>([]);




  const [breadCrumps, setBreadCrumps] = useState<string[]>(["Global"])
  const [navigationLayer, setNavigationLayer] = useState<number>(0);




  const [assetsDB, setAssetsDB] = useState<any>(new Loki('lokijs_database'));

  //if items changed generally tha recreate loki database
  useEffect(() => {

    assetsDB.removeCollection('elements');
    assetsDB.removeCollection('assets');


    const collection = (assetsDB as Loki).addCollection<DisplayMachineType>('elements') as any;

    items?.forEach((value, key) => {
      const location = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath].join("@@") : "Global";
      collection.insert({ ID: value.ID, Location: location });
      processAvailableAssets(["Global"]);
    });


    const collectionAssets = (assetsDB as Loki).addCollection<DisplayMachineType>('assets') as any;

    items?.forEach((value, key) => {
      const location = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath].join("@@") : "Global";
      const searchString = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath, value.Name].join("@@") : "Global";
      collectionAssets.insert({ ID: value.ID, Location: location, SearchString: searchString, Name: value.Name });
    });


  }, [items]);





  const processAvailableAssets = (layers: string[]) => {
    if (!assetsDB) return;

    const collection = (assetsDB as Loki).getCollection('elements');
    if (!collection || !collection.data.length) return;

    let search = layers.join("@@");
    if (!search) return;

    const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

    const folders = AvailableAssetsInDeeperLayers
      .map(item => item.Location.split("@@")) // Array aus dem Location-String erstellen
      .map(locationArray => locationArray.slice(0, layers.length + 1)) // Die Arrays entsprechend der Länge von layers oder layer zuschneiden
      .map(location => location.join("@@")) // Strings rekonstruieren, um Duplikate zu eliminieren
      .filter((value, index, self) => self.indexOf(value) === index) // Duplikate eliminieren
      .map(location => location.split("@@")) // Arrays aus den Location-Strings erstellen
      .map(locationList => ({ // Objekte für die Ordner erstellen
        Name: locationList[layers.length],
        Path: locationList.slice(0, layers.length + 1)
      }));

    setfolderSuggestions(folders);
  }






  useEffect(() => {
    const newArray = breadCrumps.slice(0, navigationLayer + 1);
    setBreadCrumps(newArray);
    processAvailableAssets(newArray);
  }, [navigationLayer]);



  useEffect(() => {
    onLayerChanged && onLayerChanged(breadCrumps)
  }, [breadCrumps])

  //click on a suggestion
  const handleClickSuggestion = (element: { Name: any; Path: any; }) => {
    setBreadCrumps(element.Path);
    setNavigationLayer(element.Path.length - 1);
  }


  const handleClickSearch = (element: { Icon: string | null; Name: any; Path: any; Type: number }) => {

    console.log("elaement search click ", element)
    if (element.Type == 1) {
      setBreadCrumps(element.Path); setNavigationLayer(element.Path.length - 1);
    }
  }








  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const onSearchChange = (value: string) => { processAvailableAssetsFromSearchFiler(value) };


  const findAllOccurrences = (value: string, search: string) => {
    const indices = [];
    const string = value.toLowerCase();
    let index = string.indexOf(search);
    while (index !== -1) {
      indices.push(index);
      index = string.indexOf(search, index + 1);
    }
    return indices;
  }




  const findAllLayers = (value: string, cutpos: number[]) => {
    const layers = cutpos.map(element => {
      const searchString = value.slice(0, element);
      const array = searchString.split("@@");
      return array.length;
    });

    return layers;
  }






  const processAvailableAssetsFromSearchFiler = (value: string) => {
    if (!assetsDB) return;
    if (!value || value.trim() === "") {
      setSearchItems(null);
      return;
    }




    const collection = (assetsDB as Loki).getCollection('assets');
    if (!collection || !collection.data.length) return;



    const AvailableAssets = collection.find({ SearchString: { $regex: new RegExp(`${value}`, "i") } });




    let folderresults: string[] = [];
    const filteredAssets = AvailableAssets.map(element => {



      let getLayers;
      const indices = findAllOccurrences(element.Location, value);
      if (indices.length && (getLayers = findAllLayers(element.Location, indices)).length) {
        const layered = getLayers.map(elem => {
          const splitLocation = element.Location.split("@@");
          return splitLocation.slice(0, elem);
        });
        folderresults = folderresults.concat(layered);
      }



      const locationString = element.Location.replace(/@@/g, " >> ");
      const truncatedLocation = locationString.length > 50 ? locationString.slice(0, 50) + "..." : locationString;

      return {
        Icon: "hardware-cabinet",
        Name: element.Name + " | " + truncatedLocation,
        Path: element.Location,
        Type: 0 //asset entry
      };
    });





    const uniqueFolderResults = folderresults.filter((arr, index) => {
      return index === folderresults.findIndex(innerArr => {
        return JSON.stringify(innerArr) === JSON.stringify(arr);
      });
    });

    console.log(uniqueFolderResults);
    const SearchItemsFolder = uniqueFolderResults.map(elem => {
      const nameString = Array.isArray(elem) ? elem.join(" >> ") : elem;

      return {
        Icon: "layers",
        Name: nameString,
        Path: elem,
        Type: 1 //folder entry
      }

    })


    setSearchItems([...SearchItemsFolder, ...filteredAssets]);




  }









  const onFocus = (e: any) => {
    setIsSearchFocused(true);
  }
  const onBlur = (e: any) => {
    setIsSearchFocused(false);
  }





  return (
    <div style={{ display: 'flex', marginBottom: '0.5rem', position: 'relative' }}>
      <div style={{ flex: 3 }}>

        <div ref={breadCrumpRef}>
          <IxBreadcrumb>
            {
              breadCrumps.map((element, index) => (
                <IxBreadcrumbItem key={index} icon={
                  index === 0 ? "globe" :
                    index === 1 ? "building1-filled" :
                      "layers"
                }
                  label={element} onClick={() => { setNavigationLayer(index); }} />
              ))
            }
          </IxBreadcrumb>
        </div>

      </div>
      <div style={{ position: 'relative', flex: 1 }} onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget) && (breadCrumpRef.current && !breadCrumpRef.current.contains(e.relatedTarget))) {
          onBlur(null);
        }
      }}>
        <SearchInput
          placeholder="Filter displayed assets here..."
          handleChange={onSearchChange}
          handleSubmit={onSearchChange}
          handleOnFocus={onFocus}
          handleOnBlur={onBlur}
          deleteInputAfterSubmit={false}

        />


        {isSearchFocused && (
          <div
            style={{
              marginTop: "-0.3rem",
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(243, 243, 240, 0.9)',
              zIndex: 999,
            }}

          >







            {folderSuggestions && folderSuggestions.length > 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Suggestions</IxTypography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {folderSuggestions.map((element, index) => (
                    <IxChip
                      icon="layers"
                      style={{ marginBottom: "0.5rem" }}
                      key={index}
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickSuggestion(element);
                      }}
                    >
                      {element.Name}
                    </IxChip>
                  ))}
                </div>
              </div>
            )}

            {folderSuggestions && folderSuggestions.length === 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Suggestions    ...no further suggestions found</IxTypography>


              </div>
            )}


            {searchItems && searchItems.length > 0 && (
              <div>
                <IxTypography style={{ margin: "0.5rem" }} format="display-xs">Search results...</IxTypography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {searchItems.map((element, index) => (
                    <IxChip
                      icon={element.Icon || "layers"}
                      style={{ marginBottom: "0.5rem" }}
                      key={index}
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickSearch(element);
                      }}
                    >
                      {element.Name}
                    </IxChip>
                  ))}
                </div>
              </div>
            )}




          </div>




        )
        }

      </div >

    </div >
  );
};

export { BreadCrumpNavigation };

/*


  const processAvailableAssets = (layers?: string[] | undefined) => {
    if (assetsDB) {
      const collection = (assetsDB as Loki).getCollection('elements');

      let bColletionAvailable = (collection != null && collection.data.length);
      let search = null;
      if (layers) {
        search = layers.join("@@");
      }
      else {
        if (Array.isArray(layer) && layer.length) {
          search = layer.join("@@");
        }
      }


      if (bColletionAvailable && search) {

        //let search = layer.join("@@");

        const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

        //create folder list
        const folders = AvailableAssetsInDeeperLayers
          .map(item => item.Location)                                           //only process Location Entry       
          .map(location => location.split("@@"))                                //make array out of string
          .map(locationArray => locationArray.slice(0, layer.length + 1))       //cut arrays according to layer length
          .map(location => location.join("@@"))                                 //reconstruct string our of arrays to eliminate duplicates easy
          .filter((value, index, self) => self.indexOf(value) === index)      	//eliminate duplicates
          .map(location => location.split("@@"))                                //create back an array from location string
          .map(locationList => {                                                //create folder elements of the n+1 layer
            const name = locationList[layer.length];
            const path = locationList.slice(0, layer.length + 1);
            return { Name: name, Path: path };
          });


        setfolderSuggestions(folders);

      }
    }
  }

  */






















/*


import React, { useState, useRef, useEffect } from 'react';
import { IxBreadcrumb, IxBreadcrumbItem } from '@siemens/ix-react';
import { SearchInput } from '../search-input';

import { DisplayMachinesMap, DisplayMachineType } from '../../displaytypes/displaytypes';
import Loki from 'lokijs';

import { IxChip, IxContentHeader, IxDrawer } from '@siemens/ix-react';
import { marginTop } from '../donut/constants';

interface BreadCrumpNavigationProps {
items: DisplayMachinesMap | null | undefined;
}


const BreadCrumpNavigation: React.FC<BreadCrumpNavigationProps> = ({ items }) => {
const [isSearchFocused, setIsSearchFocused] = useState(false);
const onSearchChange = (value: string) => { console.log('OnSearchChange:', value); };



const breadCrumpRef = useRef(null);


//save suggestions for the current navigationlayer
const [folderSuggestions, setfolderSuggestions] = useState<{ Name: any; Path: any; }[]>([]);




const [breadCrumps, setBreadCrumps] = useState<string[]>(["Global"])
const [navigationLayer, setNavigationLayer] = useState<number>(0);






//if items changed generally tha recreate loki database
useEffect(() => {

  assetsDB.removeCollection('elements');
  const collection = (assetsDB as Loki).addCollection<DisplayMachineType>('elements') as any;

  items?.forEach((value, key) => {
    const location = value.Info && value.Info.locationpath ? ["Global", ...value.Info.locationpath].join("@@") : "Global";
    collection.insert({ ID: value.ID, Location: location });
  });
  processAvailableAssets();
}, [items]);





const processAvailableAssets = (layers?: string[] | undefined) => {
  if (!assetsDB) return;

  const collection = (assetsDB as Loki).getCollection('elements');
  if (!collection || !collection.data.length) return;

  let search = layers ? layers.join("@@") 
  : (Array.isArray(layer) && layer.length ? layer.join("@@") : null);
  if (!search) return;


  const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

  const folders = AvailableAssetsInDeeperLayers
    .map(item => item.Location.split("@@")) // Array aus dem Location-String erstellen
    .map(locationArray => locationArray.slice(0, layers ? layers.length + 1 : layer.length + 1)) // Die Arrays entsprechend der Länge von layers oder layer zuschneiden
    .map(location => location.join("@@")) // Strings rekonstruieren, um Duplikate zu eliminieren
    .filter((value, index, self) => self.indexOf(value) === index) // Duplikate eliminieren
    .map(location => location.split("@@")) // Arrays aus den Location-Strings erstellen
    .map(locationList => ({ // Objekte für die Ordner erstellen
      Name: locationList[layers ? layers.length : layer.length],
      Path: locationList.slice(0, layers ? layers.length + 1 : layer.length + 1)
    }));

  setfolderSuggestions(folders);
}






const [assetsDB, setAssetsDB] = useState<any>(new Loki('lokijs_database'));
useEffect(() => { processAvailableAssets(); }, [assetsDB]);

const [layer, setLayer] = useState<string[]>(["Global"])
useEffect(() => {

  const helper = Array.from(breadCrumps);
  const newArray = helper.slice(0, navigationLayer + 1);
  setBreadCrumps(newArray);
  setLayer(newArray);
  processAvailableAssets(newArray);

}, [navigationLayer]);




//click on a suggestion
const handleClickSuggestion = (element: { Name: any; Path: any; }) => {

  //update variables for correct navigtion
  setBreadCrumps(element.Path);
  setLayer(element.Path);
  setNavigationLayer(element.Path.length - 1);
}








const onFocus = (e: any) => {
  setIsSearchFocused(true);
}
const onBlur = (e: any) => {
  setIsSearchFocused(false);
}

return (
  <div style={{ display: 'flex', marginBottom: '0.5rem', position: 'relative' }}>
    <div style={{ flex: 3 }}>

      <div ref={breadCrumpRef}>
        <IxBreadcrumb>
          {
            breadCrumps.map((element, index) => (
              <IxBreadcrumbItem key={index} icon={index === 0 ? "building1-filled" : (index === breadCrumps.length - 1 ? "hardware-cabinet" : "layers")} label={element} onClick={() => { setNavigationLayer(index); }} />
            ))
          }
        </IxBreadcrumb>
      </div>

    </div>
    <div style={{ position: 'relative', flex: 1 }} onBlur={(e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        onBlur(null);
      }
    }}>
      <SearchInput
        placeholder="Filter displayed assets here..."
        handleChange={onSearchChange}
        handleSubmit={onSearchChange}
        handleOnFocus={onFocus}
        handleOnBlur={onBlur}
        deleteInputAfterSubmit={false}

      />


      {isSearchFocused && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 999,
          }}

        >

          <IxContentHeader
            variant="primary"
            header-title="Suggestions"
            style={{ margin: "0.5rem" }}
          />




          {folderSuggestions.map((element, index) => (
            <IxChip key={index} outline onClick={(e) => {
              e.stopPropagation();
              handleClickSuggestion(element);
            }}>{element.Name}</IxChip>
          ))}

          <IxContentHeader
            variant="primary"
            header-title="Search results..."
            style={{ margin: "0.5rem" }}
          />

        </div>


      )}

    </div>

  </div>
);
};

export { BreadCrumpNavigation };

/*


const processAvailableAssets = (layers?: string[] | undefined) => {
  if (assetsDB) {
    const collection = (assetsDB as Loki).getCollection('elements');

    let bColletionAvailable = (collection != null && collection.data.length);
    let search = null;
    if (layers) {
      search = layers.join("@@");
    }
    else {
      if (Array.isArray(layer) && layer.length) {
        search = layer.join("@@");
      }
    }


    if (bColletionAvailable && search) {

      //let search = layer.join("@@");

      const AvailableAssetsInDeeperLayers = collection.find({ Location: { $regex: new RegExp(`^${search + "@@"}`) } });

      //create folder list
      const folders = AvailableAssetsInDeeperLayers
        .map(item => item.Location)                                           //only process Location Entry       
        .map(location => location.split("@@"))                                //make array out of string
        .map(locationArray => locationArray.slice(0, layer.length + 1))       //cut arrays according to layer length
        .map(location => location.join("@@"))                                 //reconstruct string our of arrays to eliminate duplicates easy
        .filter((value, index, self) => self.indexOf(value) === index)      	//eliminate duplicates
        .map(location => location.split("@@"))                                //create back an array from location string
        .map(locationList => {                                                //create folder elements of the n+1 layer
          const name = locationList[layer.length];
          const path = locationList.slice(0, layer.length + 1);
          return { Name: name, Path: path };
        });


      setfolderSuggestions(folders);

    }
  }
}

*/

