import { useEffect, useState, useRef } from 'react'

import { v4 as uuidv4 } from 'uuid';

import { getMachineByID, getAllMachines, getSyncEvents, getDownloadFile, getMachineAdditionalInfo, getHealthState, getQuery } from '../../requests/Requests'
import { Machine, MachineAdditionalInfo, SyncEvent } from './useMachineHookTypes';

import { useAuth } from '../useAuth';

import { LightPropTypes } from '../../atoms/trafficlight/TrafficLightPropTypes'
import { BlockList } from 'net';

const useMachine = (ID: string) => {
  const [machineInformation, setMachineInformation] = useState<null | Machine>(null);
  const auth = useAuth();

  useEffect(() => {
    getMachineByID(auth.getAuth(), ID).then((result) => {
      setMachineInformation(result);
    });

  }, [ID])

  return { machineInformation };
}
export { useMachine }






const useMachineAdditionalInfo = (ID: string) => {
  const [machineAdditionalInformation, setMachineAdditionalInformation] = useState<null | MachineAdditionalInfo>(null);
  const auth = useAuth();

  useEffect(() => {
    getMachineAdditionalInfo(auth.getAuth(), ID).then((result) => {
      setMachineAdditionalInformation(result);
    });

  }, [ID])

  return { machineAdditionalInformation };
}
export { useMachineAdditionalInfo }






const useAllMachines = () => {
  const [machines, setMachines] = useState<null | Machine[]>(null);
  const auth = useAuth();

  useEffect(() => {
    getAllMachines(auth.getAuth()).then((result) => {
      setMachines(result);
    });

  }, [])

  return { machines };
}
export { useAllMachines }



const useGetSyncEvents = (ID: string) => {
  const [syncEvents, setSyncEvent] = useState<null | SyncEvent[]>(null);
  const [AssetID, setAssetID] = useState<null | string>(null);
  const auth = useAuth();

  useEffect(() => {
    setAssetID(ID);
    getSyncEvents(auth.getAuth(), ID).then((result) => {
      console.log(result);
      setSyncEvent(result);

    });
  }, [])

  const UpdateSyncEvents = (loading: React.Dispatch<React.SetStateAction<Boolean>>) => {
    loading(true);
    getSyncEvents(auth.getAuth(), AssetID ? AssetID : "").then((result) => {
      setSyncEvent(result);
      setTimeout(() => { loading(false) }, 1000);
    });
  }


  return { syncEvents, UpdateSyncEvents };
}

export { useGetSyncEvents }


const useDownloadFile = () => {
  const auth = useAuth();
  const Download = (loading: React.Dispatch<React.SetStateAction<Boolean>>, AssetID: string, SyncEvent: string, DownloadKey: string) => {

    if (loading) loading(true);
    getDownloadFile(auth.getAuth(), AssetID, SyncEvent, DownloadKey).then((response) => {

      if (loading) { setTimeout(() => { loading(false) }, 200); }

      if (response?.status == 200) {
        // Extrahiere den Dateinamen aus dem Content-Disposition-Header
        try {
          const contentDisposition = response.headers['content-disposition'];
          const fileNameMatch = contentDisposition && contentDisposition.match(/filename\s*=\s*["']?([^'"]+)["']?/);
          const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded-file';

          //const blob = new Blob(atob(response.data), { type: 'application/octet-stream' });


          // Erstelle einen Download-Link und klicke ihn automatisch an
          //  const url = window.URL.createObjectURL(blob);
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        catch (error) {
          console.error('Error downloading file:', error);
        }

      }
    })
  }



  const DownloadSilent = (AssetID: string, SyncEvent: string, DownloadKey: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await getDownloadFile(auth.getAuth(), AssetID, SyncEvent, DownloadKey);

        if (response?.status === 200) {
          // Extrahiere den Dateinamen aus dem Content-Disposition-Header
          const contentDisposition = response.headers['content-disposition'];
          const fileNameMatch = contentDisposition && contentDisposition.match(/filename\s*=\s*["']?([^'"]+)["']?/);
          const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded-file';

          const blob = new Blob([response.data], { type: 'application/octet-stream' });

          // Erstelle einen Download-Link und klicke ihn automatisch an
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);

          // Klicke den Link automatisch an
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          resolve();

          /*
                    // Setze einen Timeout, um sicherzustellen, dass der Link erst nach dem Download entfernt wird
                    setTimeout(() => {
                      document.body.removeChild(link);
                      window.URL.revokeObjectURL(url);
                      resolve(); // Das Promise wird aufgelöst, wenn der Timeout abgeschlossen ist
                    }, 1000); // Anpassen der Timeout-Zeit nach Bedarf
                    */

        } else {
          console.log(response);
          reject(`Download failed with status code: ${response?.status}`);
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        reject('Error downloading file');
      }
    });
  };


  return { Download, DownloadSilent };

}
export { useDownloadFile }












const useTrafficLight = (AssetID: string) => {

  const UpdateInterval = 10 * 1000;

  const auth = useAuth();

  const [trafficLight, setTrafficLight] = useState<LightPropTypes>({ red: false, yellow: false, green: false })
  const [autoUpdate, setAutoUpdate] = useState<Boolean>(true);
  const timerIdRef = useRef<any | null>(null);


  const healthfromapiresponse = (value: number): LightPropTypes => {

    let result: LightPropTypes = { red: false, yellow: false, green: false };

    if (value < 0.2) { result.red = true; }
    else if (value > 0.7) { result.green = true; }
    else { result.yellow = true; }

    return result;

  }


  const updateTrafficLight = () => {


    getHealthState(auth.getAuth(), AssetID).then((result) => {
      if (result) { setTrafficLight(healthfromapiresponse(result.value)) }

      console.log(result);
      //setSyncEvent(result);
    });


  }



  //handling autoupdate functionality
  useEffect(() => {
    /*
    if (autoUpdate) { timerIdRef.current = setInterval(updateTrafficLight, 2000); }
    else {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    }

    return () => {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
      }
    };
    */
  }, [autoUpdate]);
  const EnableUpdate = (active: Boolean) => { setAutoUpdate(active); }


  //initial call
  useEffect(updateTrafficLight, [])


  return { trafficLight, EnableUpdate };

}
export { useTrafficLight }







const useQuery = () => {
  const auth = useAuth();

  const QueryData = async (AssetID: string, MetricID: string, LatestValue?: boolean, From?: Date, To?: Date) => {
    try {
      const result = await getQuery(auth.getAuth(), AssetID, MetricID, LatestValue, From, To);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { QueryData };
};

export { useQuery };