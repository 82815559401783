import React, { useRef } from 'react'
import { IxButton, IxIconButton, Modal, ModalRef } from '@siemens/ix-react'
import { IAppProps } from '../../../App'
import './CustomModal.css'

function CustomModal({ logout }: IAppProps | any) {
  const modalRef = useRef<ModalRef>(null)

  const dismiss = () => {
    modalRef.current?.dismiss('dismiss payload')
  }

  return (
    <Modal ref={modalRef}>
      <div className="modal-header">
        Logout
        <IxIconButton
          data-testid="test-cancelMark"
          data-button-close
          ghost
          icon="close"
          onClick={() => dismiss()}
        ></IxIconButton>
      </div>
      <div className="modal-body" data-testid="test-Modal">
        Are you sure you want to logout?
      </div>
      <div className="modal-footer" style={{ justifyContent: 'normal' }}>
        <IxButton outline onClick={() => dismiss()}>
          Cancel
        </IxButton>
        <IxButton data-testid="test-logoutButton" onClick={() => {
          logout();
          dismiss();
        }}>
          OK
        </IxButton>
      </div>
    </Modal>
  )
}

export default CustomModal

//  <IxButton data-testid="test-logoutButton" onClick={logout}>