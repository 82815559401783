import React, { useEffect, useState } from 'react';
import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';
import { MachineTypeSummary } from './machinetype-summary';
import { createContext, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Loki from 'lokijs';
import { useMachineData } from '../../hooks/useMachineData';
import { useParams } from 'react-router-dom';

import { useTitleInfo } from '../../hooks/useTitleInfo';


import { MachineList } from '../../atoms/machine-navitem';
import { MachineTypeDetail } from './machinetype-detail';
import { IxSpinner } from '@siemens/ix-react';

interface MachineTypeOverviewProps {
}






const Overview = ({ dataMap }: { dataMap: Map<string, any> }) => {
  const navigate = useNavigate();
  const { title, setTitle } = useTitleInfo();


  useEffect(() => { setTitle({ title: null, hasBackButton: false, backLink: null }) }, [])

  return (

    <div style={{ display: "flex", gap: "3rem", flexWrap: "wrap" }}>
      {dataMap && Array.from(dataMap.entries()).map(([key, value]) => {
        const title = key === "d1eca0b8-be6c-4333-b423-70209b84cd2e" ? "Coater" : key === "1b3aac33-670f-4ce7-b6fd-43442cd83d43" ? "Roll Press" : key === "f82b14ce-a838-4bca-bb7d-313bc140fa6b" ? "Mixer" : "";
        if (title === "") return null;
        return (
          <MachineTypeSummary key={key} summary={value.donutdata} title={title} onClick={(sev) => {
            if (sev != null) { navigate(`${key}?filter=${sev}`); }
            else { navigate(key); }
          }}
          />
        );
      })}
    </div>

  );
};












const MachineTypeOverview: React.FC<MachineTypeOverviewProps> = () => {

  const [dataMap, setDataMap] = useState<Map<string, { typestring: string, donutdata: { good: number, optimal: number, payattention: number, warning: number }, machines: DisplayMachineType[] }>>(new Map());
  const { filteredAssets: displayAssets, displayMachines: items } = useMachineData();
  const { setFilteredAssetsByType } = useMachineData();




  const createDataMap = (machines: Map<string, string[]>) => {
    let data = new Map<string, { typestring: string, donutdata: { good: number, optimal: number, payattention: number, warning: number }, machines: DisplayMachineType[] }>();


    Array.from(machines).map(([key, value]) => {
      let elem: { typestring: string, donutdata: { good: number, optimal: number, payattention: number, warning: number }, machines: DisplayMachineType[] } = {
        typestring: "",
        donutdata: { good: 0, optimal: 0, payattention: 0, warning: 0 },
        machines: []
      };


      elem.typestring = (key == "d1eca0b8-be6c-4333-b423-70209b84cd2e") ? "Coater" : (key == "aaad1eca0b8-be6c-4333-b423-70209b84cd2e") ? "Press" : "unknown type";

      value.forEach(element => {
        let obj = items?.get(element);
        if (obj?.Health) {
          if (obj.Health <= 2.5) elem.donutdata.warning++;
          else if (obj.Health <= 5) elem.donutdata.payattention++;
          else if (obj.Health <= 7.5) elem.donutdata.good++;
          else elem.donutdata.optimal++;


          elem.machines.push(obj);
        }
      });
      data.set(key, elem);
    }
    )
    setDataMap(data);

  }



  const sortAssets = (list: string[]) => {

    if (!items) return;
    const newMap = new Map<string, string[]>();

    console.log("display assets", displayAssets);

    displayAssets.forEach((element) => {
      const machine = items.get(element);
      if (machine) {
        if (machine.TypeList[0]) {
          const listItem = machine.TypeList[0];
          if (newMap.has(listItem)) {
            newMap.get(listItem)!.push(machine.ID);
          } else {
            newMap.set(listItem, [machine.ID]);
          }
        }
      }
    });
    createDataMap(newMap);
    setFilteredAssetsByType(newMap);
  }
  useEffect(() => { sortAssets(displayAssets); }, [displayAssets]);




  useEffect(() => {
    console.log("display assets", displayAssets)

  }, [displayAssets]);



  return (
    <>
      {
        displayAssets && displayAssets.length > 0 ? (

          <div style={{ width: "100%" }}>
            <Routes>
              <Route path="/" element={<Overview dataMap={dataMap} />} />
              <Route path="/:typeID/:assetID?" element={<MachineTypeDetail />} />
            </Routes>
          </div>

        ) : (
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
            <div>
              <IxSpinner size="large" />
              <div>Loading asset data ...</div>
            </div>
          </div>

        )
      }
    </>
  );

}

export { MachineTypeOverview };

/*
  <Route path="/:typeID/:assetID?" element={<Simple2 />} />
*/ 