import React, { useRef } from 'react'

import { ICustomLogoutModalPropTypes } from './CustomLogoutModalPropTypes';
import './CustomLogoutModal.css'
import { IxButton, IxIconButton } from '@siemens/ix-react'

const CustomModal2 = ({ isOpen, onConfirm, onCancel }: ICustomLogoutModalPropTypes) => {

  if (!isOpen) return null;

  return (
    <div className='modal-overlay-custom-logout'>
      <div className='modal-element-custom-logout'>
        <div className="modal-header">
          Logout
          <IxIconButton
            data-testid="test-cancelMark"
            data-button-close
            ghost
            icon="close"
            onClick={onCancel}
          ></IxIconButton>
        </div>

        <div className="modal-body" data-testid="test-Modal">
          Are you sure you want to logout?
        </div>
        <div className="modal-footer" style={{ justifyContent: 'normal' }}>
          <IxButton outline onClick={onCancel}>
            Cancel
          </IxButton>
          <IxButton data-testid="test-logoutButton" onClick={onConfirm}>
            OK
          </IxButton>
        </div>

      </div>
    </div >
  );
};



export default CustomModal2;

/*

    <Modal ref={modalRef}>
      <div className="modal-header">
        Logout
        <IxIconButton
          data-testid="test-cancelMark"
          data-button-close
          ghost
          icon="close"
          onClick={() => dismiss()}
        ></IxIconButton>
      </div>
      <div className="modal-body" data-testid="test-Modal">
        Are you sure you want to logout?
      </div>
      <div className="modal-footer" style={{ justifyContent: 'normal' }}>
        <IxButton outline onClick={() => dismiss()}>
          Cancel
        </IxButton>
        <IxButton data-testid="test-logoutButton" onClick={() => {
          logout();
          dismiss();
        }}>
          OK
        </IxButton>
      </div>
    </Modal>

























const CustomModal2 = ({ isOpen, onClose, children }: ICustomModal2PropTypes) => {

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-custom2">
      <div className="modal-custom2">

        <div>{children}</div>
      </div>
    </div>
  );
};
*/