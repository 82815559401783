import { DisplayMachineType } from "../displaytypes/displaytypes";
import { Machine } from "../hooks/use-machine/useMachineHookTypes";

import { useAuth } from "../hooks/useAuth";

import { getMachineState, getMachineAdditionalInfo, getHealthState } from "../requests/Requests";


import Loki from 'lokijs';

type DisplayMachinesMap = Map<string, DisplayMachineType>;







const fillHealthIndicators = async (token: string, assetID: string, callback: (healthIndicators: any) => void) => {
  // Hier füllst du die Health-Indikatoren asynchron und rufst dann den Callback auf
  const healthIndicators = await getHealthState(token, assetID);
  callback(healthIndicators);
};


const fetchHealthIndicators_SuperCoater3000 = async (token: string, assetid: string) => {

}






export const getDisplayMachines = async (token: string, machines: Machine[] | null, display: DisplayMachinesMap | null | undefined): Promise<DisplayMachinesMap> => {
  const result: DisplayMachinesMap = display ? new Map(display) : new Map<string, DisplayMachineType>();

  if (machines === null) {
    result.clear();
  } else {
    const machinePromises = machines.map(async (machine) => {
      if (!result.has(machine.assetid)) {
        const statePromise = getMachineState(token, machine.assetid);
        const infoPromise = getMachineAdditionalInfo(token, machine.assetid);
        const healthPromise = getHealthState(token, machine.assetid);


        const [state, info, health] = await Promise.all([statePromise, infoPromise, healthPromise]);

        result.set(machine.assetid, {
          ID: machine.assetid,
          Name: machine.name ?? "demo",
          Description: machine.description ?? "",
          Type: machine.machinetypeid ?? "",
          State: state,
          Info: info ?? null,
          TypeList: machine.machinetypelist,
          Health: health?.value ?? null,
          HealthIndicators: {},
        });




      }
    });

    await Promise.all(machinePromises);
  }

  return result;
};










/*
// Beispiel für die Funktion, die später die Health-Indikatoren füllt
const fillHealthIndicators = async (token: string, assetID: string, callback: (healthIndicators: any) => void) => {
  // Hier füllst du die Health-Indikatoren asynchron und rufst dann den Callback auf
  const healthIndicators = await getHealthState(token, assetID);
  callback(healthIndicators);
};



export const getDisplayMachines = async (token: string, machines: Machine[] | null, display: DisplayMachinesMap | null | undefined): Promise<DisplayMachinesMap> => {
  const result: DisplayMachinesMap = display ? new Map(display) : new Map<string, DisplayMachineType>();

  if (machines === null) {
    result.clear();
  } else {
    const machinePromises = machines.map(async (machine) => {
      if (!result.has(machine.assetid)) {
        const statePromise = getMachineState(token, machine.assetid);
        const infoPromise = getMachineAdditionalInfo(token, machine.assetid);
        const healthPromise = getHealthState(token, machine.assetid);


        fillHealthIndicators(token, machine.assetid, (healthIndicators) => {
          const updatedMachine = result.get(machine.assetid);
          if (updatedMachine) {
            updatedMachine.HealthIndicators = healthIndicators;
            result.set(machine.assetid, updatedMachine);
          }
        });

        const [state, info, health] = await Promise.all([statePromise, infoPromise, healthPromise]);

        result.set(machine.assetid, {
          ID: machine.assetid,
          Name: machine.name ?? "demo",
          Description: machine.description ?? "",
          Type: machine.machinetypeid ?? "",
          State: state,
          Info: info ?? null,
          TypeList: machine.machinetypelist,
          Health: health?.value ?? null,
          HealthIndicators: {},
        });




      }
    });

    await Promise.all(machinePromises);
  }

  return result;
};

*/





