import React from 'react'
import { IxSpinner } from '@siemens/ix-react'
import { ILoaderPropType } from './LoaderPropTypes'

function Loader({ className, size = 'medium' }: ILoaderPropType) {
  return (
    <div className={className}>
      <IxSpinner size={size}></IxSpinner>
    </div>
  )
}

export { Loader }
