import { NavigateFunction } from 'react-router-dom'
import { IMenuItemTypes } from '../../atoms/navigation/NavigationPropTypes'
import { getFirstLetterFromAzureUsername } from '../../utils/utils'

const navigationItems = (
  navigate: NavigateFunction,
  show: () => Promise<void>,
  pathname: string,
  userName: string = ''
): IMenuItemTypes[] => {
  return [

    {
      id: 1,
      icon: 'user',
      name: 'Profile',
      avatar: true,
    },
    {
      id: 2,
      icon: 'home',
      name: 'Home',
      onClick: () => navigate('/'),
      isActive: pathname === '/' ? true : false,
    }

  ]
}

export { navigationItems }
