import React, { useEffect, useState } from 'react';


import { useNavigate } from 'react-router-dom';
import { useMachineData } from '../../hooks/useMachineData';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTitleInfo } from '../../hooks/useTitleInfo';
import { MachineList } from '../../atoms/machine-navitem';

import { IxEmptyState } from '@siemens/ix-react';
import { MachineHealth } from '../machine-health';
import { filter } from 'lodash';


interface MachineTypeDetailProps {

}


const MachineTypeDetail: React.FC<MachineTypeDetailProps> = () => {

  const navigate = useNavigate();

  const { filteredAssetsByType, displayMachines, filteredAssets } = useMachineData();
  const { typeID, assetID } = useParams();
  const { title, setTitle } = useTitleInfo();


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const helper = queryParams.get('filter');
  const severityFilter = helper && !isNaN(Number(helper)) ? Number(helper) : null;








  const [activeAssetID, setActiveAssetID] = useState<string | null>(assetID ?? null);
  const updateHeader = () => {
    let asset = displayMachines?.get(activeAssetID ?? "");
    if (asset) {
      setTitle({ title: `Machine Risk Metrics - ${asset.Name}`, hasBackButton: true, backLink: "/" });
    } else {
      setTitle({ title: `Machine Risk Metrics`, hasBackButton: true, backLink: "/" });
    }
  }
  useEffect(updateHeader, [activeAssetID]);


  useEffect(() => {
    let bFound = filteredAssetsByType.get(typeID ?? "")?.includes(activeAssetID ?? "");
    if (!bFound) setActiveAssetID(null);
  }, [filteredAssetsByType]);



  return (


    <div style={{ display: "flex", gap: "2rem" }}>
      <MachineList severityFilter={severityFilter} activeAssetID={activeAssetID} onClickAsset={(assetID) => { setActiveAssetID(assetID) }} />
      <div style={{ flex: 1 }}>
        {activeAssetID == null ?
          <IxEmptyState style={{ width: "100%" }} header="No asset selected" subHeader="Select an asset first" icon="controller-device" /> :
          <MachineHealth assetID={activeAssetID} type={typeID} />
        }
      </div>
    </div>
  );

}

export { MachineTypeDetail };

