
import React, { createContext, useContext, useState } from 'react';
import { AuthProviderProps } from './useAuthTypes';
import { DisplayMachinesMap } from '../displaytypes/displaytypes';




type MachineDataContextValue = {
  displayMachines: DisplayMachinesMap | null;
  setDisplayMachines: React.Dispatch<React.SetStateAction<DisplayMachinesMap | null>>,
  breadCrumps: string[],
  setBreadCrumps: React.Dispatch<React.SetStateAction<string[]>>
  filteredAssets: string[],
  setFilteredAssets: React.Dispatch<React.SetStateAction<string[]>>,
  filteredAssetsByType: Map<string, string[]>,
  setFilteredAssetsByType: React.Dispatch<React.SetStateAction<Map<string, string[]>>>,
};



// Create the context with an initial value (you can customize the initial state as needed)
const MachineDataContext = createContext<MachineDataContextValue | undefined>(undefined);

// Create a custom hook to access the AuthContext
export const useMachineData = (): MachineDataContextValue => {
  const context = useContext(MachineDataContext);
  if (!context) {
    throw new Error('useMachineData must be used within an MachineDataProvider');
  }
  return context;
};

interface MachineDataProviderProps {
  children: React.ReactNode
}

// AuthProvider component that wraps your app and provides the context value
const MachineDataProvider: React.FC<MachineDataProviderProps> = ({ children }) => {
  // Example: Initialize state for isAuthenticated and setIsAuthenticated
  const [displayMachines, setDisplayMachines] = useState<DisplayMachinesMap | null>(null);
  const [breadCrumps, setBreadCrumps] = useState<string[]>(["Global"]);
  const [filteredAssets, setFilteredAssets] = useState<string[]>([]);
  const [filteredAssetsByType, setFilteredAssetsByType] = useState<Map<string, string[]>>(new Map<string, string[]>());





  // You can add other authentication-related state and methods here
  const MachineDataContextValue: MachineDataContextValue = { displayMachines, setDisplayMachines, breadCrumps, setBreadCrumps, filteredAssets, setFilteredAssets, filteredAssetsByType, setFilteredAssetsByType };


  // Return the context provider with the children as its content
  return <MachineDataContext.Provider value={MachineDataContextValue}>{children}</MachineDataContext.Provider>;
};

export default MachineDataContext;
export { MachineDataProvider };
