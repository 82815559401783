import React, { useEffect, useState } from 'react';
import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';
import { useMachineData } from '../../hooks/useMachineData';


import { IxTile, IxTypography } from '@siemens/ix-react';
import { useQuery } from '../../hooks/use-machine/useMachine';


import _ from 'lodash';


interface PressHealthProps {
  assetID: string | null,
}


/*
44917b10-c0f5-483b-8477-e63b121fb38e	outlier percentage improcement per day	Outlier in coating before and after press => improvement
338cbca2-d431-4d22-b1d7-ed5f1823246e	load on press trand per month	Load on press trend 

*/

const METRIC_OUTLIERIMPROVEMENT_DAY = "44917b10-c0f5-483b-8477-e63b121fb38e";
const METRIC_LOADPRESSTREND_MONTH = "338cbca2-d431-4d22-b1d7-ed5f1823246e";



const SeverityColors: Map<number, string> = new Map();
SeverityColors.set(-1, "red"); //optimal
SeverityColors.set(0, "#01893A"); //optimal
SeverityColors.set(1, "#909700"); //normal
SeverityColors.set(2, "#E96401"); //payattention
SeverityColors.set(3, "#ED2005"); //warning




const PressHealth: React.FC<PressHealthProps> = ({ assetID }) => {

  const { displayMachines } = useMachineData();
  const { QueryData } = useQuery();

  const [asset, setAsset] = useState<DisplayMachineType | null>(null);
  const [displayValues, setDisplayValus] = useState<any>({
    HealthIndicators: {
      METRIC_OUTLIERIMPROVEMENT_DAY: undefined,
      METRIC_LOADPRESSTREND_MONTH: undefined
    }
  })



  useEffect(() => {

    setDisplayValus(
      {
        HealthIndicators: {
          METRIC_OUTLIERIMPROVEMENT_DAY: undefined,
          METRIC_LOADPRESSTREND_MONTH: undefined
        }
      })

    setAsset(displayMachines?.get(assetID ?? "") ?? null);
  }, [displayMachines, assetID]);


  const mapHealthToString = (health: number): string => {

    let value = "unknown";
    if (health == 0) value = "Optimal";
    if (health == 1) value = "Normal";
    if (health == 2) value = "Pay Attention";
    if (health == 3) value = "Warning";
    return value;
  }


  const getHealthIndicators = async () => {
    if (asset) {

      const updatedDisplayValues = _.cloneDeep(displayValues) || {};

      //update METRIC_COATINGTHIKNESS_OUTLIERPERDAY if not exist
      if (typeof (updatedDisplayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY) === "undefined") {
        let value = await QueryData(asset.ID, METRIC_OUTLIERIMPROVEMENT_DAY, true);
        updatedDisplayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY = value !== null ? value[0] : undefined;
      }


      //update  METRIC_COATINGTHIKNESS_OUTLIERPERDAY if not exist
      if (typeof (updatedDisplayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH) === "undefined") {
        let value = await QueryData(asset.ID, METRIC_LOADPRESSTREND_MONTH, true);
        updatedDisplayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH = value !== null ? value[0] : undefined;
      }


      setDisplayValus(updatedDisplayValues);


    }
  }

  useEffect(() => { getHealthIndicators(); }, [asset]);

  return (


    <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem" }}>


      {/**Tile 1  Ratio increased out-of-spec coating thickness in a day*/}
      <div style={{ minWidth: "400px", maxWidth: "500px", background: "#F3F3F0", padding: "2rem" }}>



        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY &&
            (typeof (displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health) != 'undefined') ? (

            <div style={{
              display: "inline-block",
              padding: "0.2rem",
              paddingLeft: "0.8rem",
              paddingRight: "0.8rem",
              background: SeverityColors.get(displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health),
              color: "white"
            }}>
              <IxTypography format="code">{mapHealthToString(displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health)}</IxTypography></div>

          ) :
            (
              <div style={{ display: "inline-block", padding: "0.2rem", paddingLeft: "0.8rem", paddingRight: "0.8rem", background: "gray", color: "white" }}><IxTypography format="code">Loading</IxTypography></div>

            )}

        <div style={{ flex: "1", marginBottom: "2rem" }}><IxTypography format="h2">Percentage of out-of-spec coating thickness improvement</IxTypography></div>




        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY &&
            (typeof (displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.outlierpercentage_improvement) != 'undefined') &&
            (typeof (displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health) != 'undefined') ? (
            <>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1 }}>
                  <IxTypography format="display-sm">Value</IxTypography>
                </div>
                <div style={{}}>
                  <IxTypography format="display-sm">
                    {displayValues?.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.outlierpercentage_improvement.toFixed(1)}%
                  </IxTypography>
                </div>
              </div>

              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1, color: SeverityColors.get(displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health) }}>
                  <IxTypography format="display-sm">Risk Level</IxTypography>
                </div>
                <div style={{ color: SeverityColors.get(displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health) }}>
                  <IxTypography format="display-sm">{mapHealthToString(displayValues.HealthIndicators.METRIC_OUTLIERIMPROVEMENT_DAY.health)}</IxTypography>

                </div>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>Loading ...</div>
          )}





        <div style={{ marginTop: "1.5rem" }}>
          <IxTypography format="display-sm">Indicator</IxTypography>
          <IxTypography format="display-xs">Irregular coating thickness</IxTypography>
        </div>
      </div>









      {/**Tile 2   Abnormal Trend of Load*/}
      <div style={{ minWidth: "400px", maxWidth: "500px", background: "#F3F3F0", padding: "2rem" }}>



        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH &&
            (typeof (displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health) != 'undefined') ? (

            <div style={{
              display: "inline-block",
              padding: "0.2rem",
              paddingLeft: "0.8rem",
              paddingRight: "0.8rem",
              background: SeverityColors.get(displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health),
              color: "white"
            }}>
              <IxTypography format="code">{mapHealthToString(displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health)}</IxTypography></div>

          ) :
            (
              <div style={{ display: "inline-block", padding: "0.2rem", paddingLeft: "0.8rem", paddingRight: "0.8rem", background: "gray", color: "white" }}><IxTypography format="code">Loading</IxTypography></div>

            )}

        <div style={{ flex: "1", marginBottom: "2rem" }}><IxTypography format="h2">Load on press kurtosis trend per month</IxTypography></div>




        {
          displayValues &&
            displayValues.HealthIndicators &&
            displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH &&
            (typeof (displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.loadonpresstrend) != 'undefined') &&
            (typeof (displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health) != 'undefined') ? (
            <>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1 }}>
                  <IxTypography format="display-sm">Value</IxTypography>
                </div>
                <div style={{}}>
                  <IxTypography format="display-sm">
                    {displayValues?.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.loadonpresstrend.toFixed(1)}%
                  </IxTypography>
                </div>
              </div>

              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ flex: 1, color: SeverityColors.get(displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health) }}>
                  <IxTypography format="display-sm">Risk Level</IxTypography>
                </div>
                <div style={{ color: SeverityColors.get(displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health) }}>
                  <IxTypography format="display-sm">{mapHealthToString(displayValues.HealthIndicators.METRIC_LOADPRESSTREND_MONTH.health)}</IxTypography>

                </div>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>Loading ...</div>
          )}





        <div style={{ marginTop: "1.5rem" }}>
          <IxTypography format="display-sm">Indicator</IxTypography>
          <IxTypography format="display-xs">Abnormal Trend of Load</IxTypography>
        </div>
      </div>







    </div >
  );

}

export { PressHealth };