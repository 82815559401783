


import React, { useRef, useState } from 'react';
import {
  IxBasicNavigation,
  IxMenu,
  IxMenuAvatar,
  IxMenuAvatarItem,
  IxMenuItem,
} from '@siemens/ix-react'
import { INavigationPropTypes } from './NavigationPropTypes'
import {
  NavigationHeaderStyles,
  NavigationSidebarStyles,
} from './NavigationStyles.css'
import './NavStyles.css'


import { IxModalSize } from '@siemens/ix';
import { IxButton, Modal, ModalRef, showModal, IxAvatar } from '@siemens/ix-react';
import CustomModal from '../customModal/CustomModal';
import CustomLogoutModal from '../customLogoutModal/CustomLogoutModal';



const Navigation = ({
  logo,
  menuItems,
  hideHeader,
  applicationName,
  children,
  logout
}: INavigationPropTypes): React.ReactElement => {



  const open = async () => {
    await showModal({
      title: 'test',
      content: <CustomModal logout={logout} />,
      centered: true,
      size: '360',
    })
  }

  const [ModalOpen, setModalOpen] = useState<boolean>(false);
  /*
  const onModalClose = () => {
    setModalOpen(false);
    if (logout) { logout() }
  }
  */
  const onModalOpen = () => setModalOpen(true);


  return (
    <>
      <CustomLogoutModal isOpen={ModalOpen} onConfirm={() => { setModalOpen(false); if (logout) logout(); }} onCancel={() => setModalOpen(false)} />

      <IxBasicNavigation
        applicationName={applicationName}
        hideHeader={hideHeader}
        style={{ zIndex: 1 }}
      >
        {!!logo && (
          <div className="placeholder-logo" slot="logo">
            {logo}

          </div>
        )}
        <IxMenu expand={false} >

          {!!menuItems?.length &&
            menuItems
              .sort((begin, end) => begin.id - end.id)
              .map((item) => {
                return item.avatar ? (
                  <IxMenuAvatar onLogoutClick={onModalOpen} key={item.id} />
                ) : (
                  <IxMenuItem
                    key={item.id}
                    tab-icon={item.icon}
                    disabled={item.disabled}
                    onClick={item.onClick}
                    active={item.isActive}
                    slot={item.slot ?? ''}
                  >
                    {item.name}
                  </IxMenuItem>
                )
              })}

        </IxMenu>
        {!!children && <div style={{ width: "100%" }}>{children}</div>}
      </IxBasicNavigation >
    </>)
}

export { Navigation }



/*



import React from 'react'
import {
  IxBasicNavigation,
  IxMenu,
  IxMenuAvatar,
  IxMenuAvatarItem,
  IxMenuItem,
} from '@siemens/ix-react'
import { INavigationPropTypes } from './NavigationPropTypes'
import {
  NavigationHeaderStyles,
  NavigationSidebarStyles,
} from './NavigationStyles.css'
import './NavStyles.css'


const Navigation = ({
  logo,
  menuItems,
  hideHeader,
  applicationName,
  children,
}: INavigationPropTypes): React.ReactElement => {
  return (
    <IxBasicNavigation
      applicationName={applicationName}
      hideHeader={hideHeader}
      className={NavigationHeaderStyles}
      style={{ zIndex: 1 }}
    >
      {!!logo && (
        <div className="placeholder-logo" slot="logo">
          {logo}
        </div>
      )}
      <IxMenu className={NavigationSidebarStyles}>

        {!!menuItems?.length &&
          menuItems
            .sort((begin, end) => begin.id - end.id)
            .map((item) => {
              return item.avatar ? (
                <IxMenuAvatar
                  key={item.id}
                  slot={item.slot ?? ''}
                  initials={item.icon}
                >
                  {item.avatarLabel?.length &&
                    item.avatarLabel?.map((mappedItem, index) => (
                      <IxMenuAvatarItem
                        key={index}
                        label={mappedItem}
                      ></IxMenuAvatarItem>
                    ))}
                </IxMenuAvatar>
              ) : (
                <IxMenuItem
                  key={item.id}
                  tab-icon={item.icon}
                  disabled={item.disabled}
                  onClick={item.onClick}
                  active={item.isActive}
                  slot={item.slot ?? ''}
                >
                  {item.name}
                </IxMenuItem>
              )
            })}

      </IxMenu>
      {!!children && <div>{children}</div>}
    </IxBasicNavigation >
  )
}

export { Navigation }
*/