import React from 'react'
import './loginPage.css'
import { ReactComponent as SiemensLogo } from '../../assets/Seimenslogo.svg'
import { IxIcon, IxTile, IxButton } from '@siemens/ix-react'
import { ReactComponent as Group } from '../../assets/Group.svg'
import { ILoginPageProps } from "./LoginPageProps"


function LoginPage({ login }: ILoginPageProps): React.ReactElement {
  return (
    <div className="LoginPage">
      <div className="HeaderBar">
        <div className="DDXContainer headerContent">
          <div className="LogoContainer">
            <SiemensLogo className="" />
          </div>
          <div className="Language">
            <div className="languageSelection">
              <div className="colorCode">English</div>
              <div>
                <IxIcon name="chevron-down-small" size="24"></IxIcon>
              </div>
              <div>
                <IxIcon name="question" size="24"></IxIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middleContent">
        <div className="DDXContainer groupItems">
          <div>
            <Group className="ddxImage" />
          </div>
          <div>
            <IxTile className="contentTile">
              <div
                className="d-flex flex-grow-1 align-items-center justify-content-between tileHeader"
                slot="header"
              >
                SIEMENS AG
              </div>
              <div slot="subheader" className="textLabel">
                Welcome to Data Driven X
              </div>
              <div className="textContent">
                <span className="">
                  We envision a cross-industry ecosystem, in which data is
                  easy-to-process and valuable for all stakeholders. With our
                  DDX platform we match IoT-data with contract information, so
                  that finance institutes, machines users and machine builders
                  benefit from more flexibility due to maximum transparency.
                </span>
              </div>
              <div>
                <IxButton
                  className="loginButton"
                  variant="primary"
                  outline={false}
                  onClick={login}
                >
                  Log in
                </IxButton>
              </div>
            </IxTile>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="DDXContainer footerLayout">
          <div className="footerText">
            <div className="footerLabel">Website</div>
            <div className="colorCode">Support</div>
          </div>
          <div className="footerText2">
            <div className="colorCode">Version 3.01</div>
            <div className="colorCode">© 2021–2023 Siemens AG</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
