import React, { useEffect, useState } from 'react';
import { DisplayMachineType, DisplayMachinesMap } from '../../displaytypes/displaytypes';

import { createContext, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Loki from 'lokijs';
import { useMachineData } from '../../hooks/useMachineData';
import { useParams } from 'react-router-dom';

import { useTitleInfo } from '../../hooks/useTitleInfo';


import { MachineList } from '../../atoms/machine-navitem';


import { MixerHealth } from './mixerhealth';
import { CoaterHealth } from './coaterhealth';
import { PressHealth } from './presshealth';

interface MachineHealthProps {
  assetID: string | null,
  type: string | undefined
}


const MachineHealth: React.FC<MachineHealthProps> = ({ assetID, type }) => {

  const navigate = useNavigate();

  if (type === "f82b14ce-a838-4bca-bb7d-313bc140fa6b") return (<MixerHealth assetID={assetID} />); //mixer
  if (type === "1b3aac33-670f-4ce7-b6fd-43442cd83d43") return (<PressHealth assetID={assetID} />); // roll press
  if (type === "d1eca0b8-be6c-4333-b423-70209b84cd2e") return (<CoaterHealth assetID={assetID} />); // coater press


  navigate("/error");
  return null;

}

export { MachineHealth };

